var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isModerator && _vm.organizerId)?_c('panel-mini-header',{attrs:{"organizer-id":_vm.organizerId}}):_vm._e(),_c('div',{staticClass:"container mat40"},[_c('div',{staticClass:"row admin-header"},[_c('div',{staticClass:"col-12 col-md-4"},[_c('h1',[_vm._v("Ustawienia "),(_vm.organizerId)?[_vm._v("profilu organizatora")]:_vm._e()],2),_c('h3',[_vm._v("Potrzebujesz pomocy? Zadzwoń")]),_c('a',{staticClass:"call-now",attrs:{"href":"tel:+502678564"}},[_vm._v("502 678 564")])]),_c('div',{staticClass:"col-12 col-md-8"},[_c('div',{staticClass:"buttons-wrap"},[_c('base-button-group',{staticClass:"admin-header__views",attrs:{"horizontal":""}},[_c('base-button',{staticClass:"admin-header__views-btn",attrs:{"btn11":"","pressed":_vm.$route.name === 'panel-setting-new'},on:{"click":function($event){return _vm.$router.push({
							name: 'panel-setting-new',
							params: _vm.routeParams,
							alias: true
						})}}},[_vm._v(" Konto "),(_vm.organizerId)?[_vm._v("organizatora")]:(_vm.operatorId)?[_vm._v("operatora")]:_vm._e()],2),_c('base-button',{staticClass:"admin-header__views-btn",attrs:{"btn11":"","pressed":_vm.$route.name === 'panel-setting-new-agreements'},on:{"click":function($event){return _vm.$router.push({
							name: 'panel-setting-new-agreements',
							params: _vm.routeParams,
							alias: true
						})}}},[_vm._v(" Moje umowy ")]),(_vm.organizerId)?_c('base-button',{staticClass:"admin-header__views-btn",attrs:{"btn11":"","pressed":_vm.$route.name === 'panel-setting-new-info-payments'},on:{"click":function($event){return _vm.$router.push({
							name: 'panel-setting-new-info-payments',
							params: _vm.routeParams,
							alias: true
						})}}},[_vm._v(" Płatności ")]):(_vm.operatorId)?_c('base-button',{staticClass:"admin-header__views-btn",attrs:{"btn11":"","pressed":_vm.$route.name === 'panel-setting-new-operator-payments'},on:{"click":function($event){return _vm.$router.push({
							name: 'panel-setting-new-operator-payments',
							params: _vm.routeParams,
							alias: true
						})}}},[_vm._v(" Płatności ")]):_vm._e(),_c('base-button',{staticClass:"admin-header__views-btn",attrs:{"btn11":"","pressed":_vm.$route.name === 'panel-setting-new-web'},on:{"click":function($event){return _vm.$router.push({
							name: 'panel-setting-new-web',
							params: _vm.routeParams,
							alias: true
						})}}},[_vm._v(" Własna domena i e-mail ")]),_c('base-button',{staticClass:"admin-header__views-btn",attrs:{"btn11":"","pressed":_vm.$route.name === 'panel-setting-new-custom'},on:{"click":function($event){return _vm.$router.push({
							name: 'panel-setting-new-custom',
							params: _vm.routeParams,
							alias: true
						})}}},[_vm._v(" Własny wygląd ")])],1)],1),_c('router-view')],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }