<template>
	<div v-if="operator" class="operator-header__operator">
		<div class="container">
            <div class="row operator-header__operator-box">
                <div class="patb8">
					Twoje zawody obsługuje <b>{{ operator.name }}</b> <span v-if="operator.phone">tel. <a :href="`tel:+48${operator.phone}`">{{ operator.phone }}</a></span>
				</div>	
			</div>	
		</div>	
	</div>
</template>

<script>
import { READ_OPERATOR } from '@/store/actions.type';
import { mapActions, mapGetters } from 'vuex';

export default {
    props: {
        operatorId: {
            type: Number,
            required: true,
        },
    },
    computed: {
        ...mapGetters(['operators']),
        operator() {
            return this.operators[this.operatorId];
        },
    },
    async created() {
        await this.READ_OPERATOR(this.operatorId);
    },
    methods: {
        ...mapActions([READ_OPERATOR]),
    },
};
</script>

<style scoped>
.operator-header__operator {
    background-color: var(--color-slate);
    margin-top: 2px;
}

.operator-header__operator-btn {
    padding: 8px 16px;
    cursor: pointer;
    display: none;
}
.operator-header__operator-btn--active {
    background-color: var(--color-charcoal-grey);
}
.operator-header__operator-box a {
	color: var(--color-white, #fff);
}
@media (min-width: 992px) {
    .operator-header__operator-btn {
        padding: 8px 16px;
        cursor: pointer;
        display: block;
    }
    .operator-header__operator-box {
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
    }
}
</style>
