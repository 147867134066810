var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-md-6 col-lg-4"},[_c('div',{staticClass:"item-competition--small"},[_c('router-link',{staticClass:"pointer",attrs:{"tag":"div","to":{
                name: 'minisite-landing',
                params: _vm.competition,
                alias: true,
            }}},[_c('base-item-competition-icon-photo',{attrs:{"competition":_vm.competition,"competition-layout":_vm.layout}}),_c('div',{staticClass:"pa16"},[_c('h4',[_vm._v(_vm._s(_vm.competition.name))]),_c('div',{staticClass:"item-competition__status",class:{
                        'item-competition__status--open':
                            _vm.competition.status === 2,
                        'item-competition__status--close':
                            _vm.competition.status !== 2,
                    }},[_vm._v(" "+_vm._s(_vm._f("asStatus")(_vm.competition.status))+" ")]),_c('p',[_vm._v(_vm._s(_vm.competition.location))]),_c('p',[_vm._v(_vm._s(_vm._f("asTime")(_vm.competition.startedTime)))])])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }