var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"containerStoperList",staticClass:"container-list",class:_vm.isLargeScreen ? 'container-list--large' : 'container-list--small'},[_c('base-input-multiple',{staticClass:"none-desktop container-list__input",attrs:{"value":_vm.numbers,"input":_vm.number,"read-only":_vm.isMobileView},on:{"change":function($event){_vm.numbers = $event},"input":function($event){_vm.number = $event}}}),_c('base-table',{ref:"table",staticClass:"list",attrs:{"headers":_vm.headers,"items":_vm.items},scopedSlots:_vm._u([{key:"header",fn:function({ sort }){return [_c('tr',{staticClass:"list__row-header"},_vm._l((_vm.headers),function(header,column){return _c('th',{key:column,class:_vm.classesHeader(column),on:{"click":function($event){return sort(column)}}},[_vm._v(" "+_vm._s(header.text)+" ")])}),0)]}},{key:"body",fn:function({ displayedItems }){return _vm._l((displayedItems),function(item,row){return _c('tr',{key:row,staticClass:"list__row-body",class:{ disabled: item.isInvalidated }},_vm._l((_vm.headers),function(header,column){return _c('td',{key:column,class:_vm.classesCell(column)},[(header.value === 'number')?_c('span',{staticClass:"list__data-number--border",on:{"click":function($event){return _vm.setMeasureToEdit(item)}}}):_vm._e(),(
                            header.value === 'number' &&
                                (item.name === 'Brak zawodnika' ||
                                    _vm.isDuplicate(item.number, displayedItems))
                        )?_c('span',{staticClass:"c-negative list__data-number--warning"},[_vm._v("!")]):_vm._e(),(header.value === 'position' && row > 0)?_c('i',{staticClass:"list__btn-position fas fa-plus",on:{"click":function($event){return _vm.swapStoperMeasure(row, row - 1)}}}):_vm._e(),(header.value === 'number')?[(
                                _vm.editingMeasure &&
                                    ((_vm.editingMeasure.id &&
                                        _vm.editingMeasure.id == item.id) ||
                                        _vm.editingMeasure.deviceTime.isSame(
                                            item.deviceTime
                                        ))
                            )?[_vm._v(" "+_vm._s(_vm.measureNumber)+" ")]:_vm._e(),_c('input',{directives:[{name:"show",rawName:"v-show",value:(
                                !_vm.editingMeasure ||
                                    _vm.editingMeasure.id !== item.id ||
                                    !_vm.editingMeasure.deviceTime.isSame(
                                        item.deviceTime
                                    )
                            ),expression:"\n                                !editingMeasure ||\n                                    editingMeasure.id !== item.id ||\n                                    !editingMeasure.deviceTime.isSame(\n                                        item.deviceTime\n                                    )\n                            "},{name:"model",rawName:"v-model",value:(item[header.value]),expression:"item[header.value]"}],ref:item.id
                                    ? item.id
                                    : item.deviceTime.valueOf(),refInFor:true,staticClass:"list__data-input",class:[{ cred: item[header.value] === '?' }],attrs:{"type":"text","readonly":_vm.isMobileView},domProps:{"value":(item[header.value])},on:{"change":function($event){return _vm.changeNumberMeasure(item)},"input":function($event){if($event.target.composing)return;_vm.$set(item, header.value, $event.target.value)}}})]:(item[header.value] === 'Brak zawodnika')?[_c('span',{staticClass:"c-negative"},[_vm._v(_vm._s(item[header.value]))])]:[_vm._v(" "+_vm._s(item[header.value])+" ")],(header.value === 'time')?_c('i',{staticClass:"list__btn-edit--mobile",class:[
                            item.isInvalidated
                                ? 'fas fa-redo'
                                : 'fas fa-plus rotate45',
                        ],on:{"click":function($event){return _vm.toggleInvalidatedStoperMeasure(row)}}}):_vm._e(),(header.value === 'clubname')?_c('i',{staticClass:"list__btn-edit--desktop",class:[
                            item.isInvalidated
                                ? 'fas fa-redo'
                                : 'fas fa-plus rotate45',
                        ],on:{"click":function($event){return _vm.toggleInvalidatedStoperMeasure(row)}}}):_vm._e()],2)}),0)})}},{key:"no-results",fn:function(){return [_c('div',{staticClass:"d-sm"},[_c('div',{staticClass:"stoper__hint"},[_c('h1',{staticClass:"stoper__hint-number"},[_vm._v(" 1. ")]),_c('p',[_vm._v("Wpisz numer startowy uczestnika i naciśnij stop.")])]),_c('div',{staticClass:"stoper__hint"},[_c('h1',{staticClass:"stoper__hint-number"},[_vm._v(" 2. ")]),_c('p',[_vm._v(" Możesz dodawać numery do kolejki, używając strzałki. ")])]),_c('div',{staticClass:"stoper__hint"},[_c('h1',{staticClass:"stoper__hint-number"},[_vm._v(" 3. ")]),_c('p',[_vm._v(" Jeśli w kolejce znajdują się numery, przyciskaj stop aby zapisać im czasy. ")])])]),_c('p',{staticClass:"d-lg"},[_vm._v(" Brak pomiarów ")])]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }