<template>
    <div
        class="input-field input-field--track pointer"
        @click="clickHandler(), $emit('click')"
    >
        <p
            v-if="value.length"
            class="input-field__file-name"
        >
            {{ value[0].name }}
        </p>
        <p
            v-else
            class="input-field__hint"
        >
            {{ placeholder }}
        </p>
        <input
            ref="file"
            class="input-field__input-file"
            type="file"
            :multiple="isMultiple"
            :accept="accept"
            :disabled="disabled"
            @change="fileHandler"
        >

        <base-button
            class="btn-add"
            btn2
            icon="fas fa-plus"
            :disabled="disabled"
            @click="clickHandler()"
        />
        <span class="input-error input-error--show">{{ say[error] || error }}</span>
    </div>
</template>

<script>
import input from '@/mixins/input';
import inputFiles from '@/mixins/input-files';
import { mapGetters } from 'vuex';

export default {
    mixins: [input, inputFiles],
	props: {
        converter: {
            type: String,
            default: 'string'
        }
    },
    computed: {
        ...mapGetters(['say']),
    },
    methods: {
		fileHandler($event) {
			this.inputFileHandler($event, this.converter)
		},
        clickHandler() {
            this.$refs.file.click();
            this.updateError();
        },
    },
};
</script>

<style scoped>
.input-field--gpx {
    position: relative;
}
.input-field--track {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-height: 40px;
    min-height: 40px;
    overflow: hidden;
}
.btn-add {
    border-radius: 0;
}
.input-field__hint {
    color: var(--color-bluish-grey);
    margin-left: 12px;
}
.input-field__file-name {
    margin-left: 12px;
    color: var(--color-slate);
}
</style>
