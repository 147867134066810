export const ADD_CHECKPOINT_GROUP = 'ADD_CHECKPOINT_GROUP';
export const ADD_OPERATOR_DEVICE = 'ADD_OPERATOR_DEVICE';
export const ADD_STOPER_MEASURE = 'ADD_STOPER_MEASURE';
export const AUTOCOMPLETE_COMPETITION_NAME = 'AUTOCOMPLETE_COMPETITION_NAME';
export const AUTOCOMPLETE_COMPETITION_PLAYER = 'AUTOCOMPLETE_COMPETITION_PLAYER';
export const BOOTSTRAP = 'BOOTSTRAP';
export const BOOTSTRAP_MINISITE = 'BOOTSTRAP_MINISITE';
export const BOOTSTRAP_STOPER = 'BOOTSTRAP_STOPER';
export const BOOTSTRAP_STOPER_SETTING = 'BOOTSTRAP_STOPER_SETTING';
export const CHANGE_LANG = 'CHANGE_LANG';
export const CONTINUE_VIA_FACEBOOK = 'CONTINUE_VIA_FACEBOOK';
export const CREATE_CHECKPOINT = 'CREATE_CHECKPOINT';
export const CREATE_DEVICE = 'CREATE_DEVICE';
export const CREATE_GATE = 'CREATE_GATE';
export const CREATE_MEASURE = 'CREATE_MEASURE';
export const CREATE_RESULT = 'CREATE_RESULT';
export const CREATE_SESSION = 'CREATE_SESSION';
export const CREATE_USER_CHILD = 'CREATE_USER_CHILD';
export const CREATE_USER = 'CREATE_USER';
export const CREATE_PLAYER = 'CREATE_PLAYER';
export const DECLARE_RESULT = 'DECLARE_RESULT';
export const DELETE_CHECKPOINT = 'DELETE_CHECKPOINT';
export const DELETE_GATE = 'DELETE_GATE';
export const DELETE_SESSION = 'DELETE_SESSION';
export const DELETE_USER_CHILD = 'DELETE_USER_CHILD';
export const DELETE_USER = 'DELETE_USER';
export const DELETE_PLAYER = 'DELETE_PLAYER';
export const DISABLE_CHECKPOINT = 'DISABLE_CHECKPOINT';
export const DISABLE_FAMILY = 'DISABLE_FAMILY';
export const ENABLE_CHECKPOINT = 'ENABLE_CHECKPOINT';
export const ENABLE_FAMILY = 'ENABLE_FAMILY';
export const SEARCH_COMPETITIONS_CAROUSEL = 'SEARCH_COMPETITIONS_CAROUSEL';
export const SEARCH_COMPETITIONS_LIST = 'SEARCH_COMPETITIONS_LIST';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const MOVE_CHECKPOINT_GROUP = 'MOVE_CHECKPOINT_GROUP';
export const READ_ACCESS_TOKEN = 'READ_ACCESS_TOKEN';
export const READ_CLASSIFICATION_CHECKPOINTS = 'READ_CLASSIFICATION_CHECKPOINTS';
export const READ_CLASSIFICATION_PLAYERS = 'READ_CLASSIFICATION_PLAYERS';
export const READ_CLASSIFICATION = 'READ_CLASSIFICATION';
export const READ_CLASSIFICATION_RESULTS = 'READ_CLASSIFICATION_RESULTS';
export const READ_CLASSIFICATION_RESULTS_POSITIONS = 'READ_CLASSIFICATION_RESULTS_POSITIONS';
export const READ_CLASSIFICATION_MEASURES = 'READ_CLASSIFICATION_MEASURES';
export const READ_COMPETITION_CLASSIFICATIONS = 'READ_COMPETITION_CLASSIFICATIONS';
export const READ_COMPETITION_ORGANIZER = 'READ_COMPETITION_ORGANIZER';
export const READ_COMPETITION_PLAYERS = 'READ_COMPETITION_PLAYERS';
export const READ_COMPETITION = 'READ_COMPETITION';
export const READ_COMPETITIONS_COUNT = 'READ_COMPETITIONS_COUNT';
export const READ_COMPETITIONS = 'READ_COMPETITIONS';
export const READ_GATE_MEASURES = 'READ_GATE_MEASURES';
export const READ_GATE = 'READ_GATE';
export const READ_LANGS = 'READ_LANGS';
export const READ_MESSAGES = 'READ_MESSAGES';
export const READ_NEXT_IN_CYCLE_COMPETITIONS = 'READ_NEXT_IN_CYCLE_COMPETITIONS';
export const READ_OPERATOR_DEVICES = 'READ_OPERATOR_DEVICES';
export const READ_OPERATOR = 'READ_OPERATOR';
export const READ_ORGANIZER = 'READ_ORGANIZER';
export const READ_ORGANIZER_LAYOUT = 'READ_ORGANIZER_LAYOUT';
export const READ_OPERATOR_LAYOUT = 'READ_OPERATOR_LAYOUT';
export const READ_PLACE_PREDICTIONS = 'READ_PLACE_PREDICTIONS';
export const READ_PLAYER = 'READ_PLAYER';
export const READ_PLAYER_CYCLE = 'READ_PLAYER_CYCLE';
export const READ_PLAYER_SHOP = 'READ_PLAYER_SHOP';
export const READ_USER_CHILD = 'READ_USER_CHILD';
export const READ_USER_CHILDREN = 'READ_USER_CHILDREN';
export const READ_USER_COMPETITION_PLAYERS = 'READ_USER_COMPETITION_PLAYERS';
export const READ_USER_COMPETITION_POLICIES_PDF = 'READ_USER_COMPETITION_POLICIES_PDF';
export const READ_USER_COMPETITIONS = 'READ_USER_COMPETITIONS';
export const READ_USER_ID = 'READ_USER_ID';
export const READ_USER_PARENTS = 'READ_USER_PARENTS';
export const READ_USER = 'READ_USER';
export const READ_COMPETITION_GATES = 'READ_COMPETITION_GATES';
export const REGISTER_USER = 'REGISTER_USER';
export const REMOVE_CHECKPOINT_GROUP = 'REMOVE_CHECKPOINT_GROUP';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_LINK = 'RESET_PASSWORD_LINK';
export const SELECT_CHECKPOINT_GROUP_DEVICE = 'SELECT_CHECKPOINT_GROUP_DEVICE';
export const SELECT_STOPER_GATE = 'SELECT_STOPER_GATE';
export const SEND_CONTACT_MAIL = 'SEND_CONTACT_MAIL';
export const SEND_PAYLINK_MAIL = 'SEND_PAYLINK_MAIL';
export const START_STOPER = 'START_STOPER';
export const RESET_STOPER = 'RESET_STOPER';
export const SWAP_STOPER_MEASURES = 'SWAP_STOPER_MEASURES';
export const TEARDOWN_STOPER = 'TEARDOWN_STOPER';
export const TEARDOWN_STOPER_SETTING = 'TEARDOWN_STOPER_SETTING';
export const UPDATE_CHECKPOINT_GROUP = 'UPDATE_CHECKPOINT_GROUP';
export const UPDATE_CHECKPOINT = 'UPDATE_CHECKPOINT';
export const UPDATE_CLASSIFICATION = 'UPDATE_CLASSIFICATION';
export const UPDATE_COMPETITION = 'UPDATE_COMPETITION';
export const UPDATE_GATE = 'UPDATE_GATE';
export const UPDATE_MEASURE = 'UPDATE_MEASURE';
export const UPDATE_USER_CHILD = 'UPDATE_USER_CHILD';
export const UPDATE_USER = 'UPDATE_USER';
export const ADD_STOPER_MEASURE_NUMBERS = 'ADD_STOPER_MEASURE_NUMBERS';
export const IMPORT_MEASURES = 'IMPORT_MEASURES';
export const READ_GOOGLE_PLACE_PREDICTIONS = 'READ_GOOGLE_PLACE_PREDICTIONS';
export const READ_GOOGLE_GEOCODE = 'READ_GOOGLE_GEOCODE';
export const READ_FACEBOOK_SIGNED_REQUEST = 'READ_FACEBOOK_SIGNED_REQUEST';
export const READ_DEVICE = 'READ_DEVICE';
export const CREATE_LOCATION = 'CREATE_LOCATION';
export const CREATE_TRACKPOINT = 'CREATE_TRACKPOINT';
export const READ_COMPETITION_TRACKPOINTS = 'READ_COMPETITION_TRACKPOINTS';
export const READ_LOCATION = 'READ_LOCATION';
export const UPDATE_TRACKPOINT = 'UPDATE_TRACKPOINT';
export const UPDATE_LOCATION = 'UPDATE_LOCATION';
export const DELETE_TRACKPOINT = 'DELETE_TRACKPOINT';
export const DELETE_LOCATION = 'DELETE_LOCATION';
export const READ_CLASSIFICATION_GPX_FILE = 'READ_CLASSIFICATION_GPX_FILE';
export const CREATE_CLASSIFICATION_GPX_FILE = 'CREATE_CLASSIFICATION_GPX_FILE';
export const ADD_FILES = 'ADD_FILES';
export const CREATE_FILES = 'CREATE_FILES';
export const READ_CLASSIFICATION_RESULTS_SPLIT = 'READ_CLASSIFICATION_RESULTS_SPLIT';
export const READ_CLASSIFICATION_RESULTS_LAP = 'READ_CLASSIFICATION_RESULTS_LAP';
export const ADD_CHECKPOINT_GROUP_META = 'ADD_CHECKPOINT_GROUP_META';
export const UPDATE_MEASURE_WITHOUT_ID = 'UPDATE_MEASURE_WITHOUT_ID';
export const READ_COMPETITION_LIMITS = 'READ_COMPETITION_LIMITS';
export const READ_COMPETITION_CLASSIFICATIONS_LIMITS = 'READ_COMPETITION_CLASSIFICATIONS_LIMITS';
export const READ_COMPETITION_CLASSIFICATIONS_AGES = 'READ_COMPETITION_CLASSIFICATIONS_AGES';
export const READ_COMPETITION_CLASSIFICATIONS_ACTIVE_PRICE = 'READ_COMPETITION_CLASSIFICATIONS_ACTIVE_PRICE';
export const READ_COMPETITION_CLASSIFICATIONS_TOP_RESULTS = 'READ_COMPETITION_CLASSIFICATIONS_TOP_RESULTS';
export const READ_CLASSIFICATION_RESULTS_POSITIONS_OPEN = 'READ_CLASSIFICATION_RESULTS_POSITIONS_OPEN';
export const CREATE_PLAYER_ADDRESS = 'CREATE_PLAYER_ADDRESS';
export const READ_DECLARE_RESULT_CONFIG = 'READ_DECLARE_RESULT_CONFIG';
export const DECLARE_ADDRESS = 'DECLARE_ADDRESS';
export const DECLARE_FILES = 'DECLARE_FILES';
export const AUTOCOMPLETE_LOCATION = 'AUTOCOMPLETE_LOCATION';
export const CREATE_USER_AVATAR = 'CREATE_USER_AVATAR';
export const BOOTSTRAP_OPERATOR = 'BOOTSTRAP_OPERATOR';
export const BOOTSTRAP_ORGANIZER = 'BOOTSTRAP_ORGANIZER';
export const READ_OPERATOR_ORGANIZERS = 'READ_OPERATOR_ORGANIZERS';
export const ADD_ORGANIZER_ROLE = 'ADD_ORGANIZER_ROLE';
export const ADD_USER_ROLE = 'ADD_USER_ROLE';
export const CREATE_ORGANIZER = 'CREATE_ORGANIZER';
export const SEND_ORGANIZER_ACCESS = 'SEND_ORGANIZER_ACCESS';
export const SEND_OPERATOR_ACCESS = 'SEND_OPERATOR_ACCESS';
export const DELETE_COMPETITION = 'DELETE_COMPETITION';
export const DELETE_ORGANIZER = 'DELETE_ORGANIZER';
export const CREATE_OPERATOR = 'CREATE_OPERATOR';
export const BOOTSTRAP_ADMIN = 'BOOTSTRAP_ADMIN';
export const READ_OPERATORS = 'READ_OPERATORS';
export const DELETE_OPERATOR = 'DELETE_OPERATOR';
export const COPY_COMPETITION = 'COPY_COMPETITION';
export const CREATE_DEFAULT_COMPETITION = 'CREATE_DEFAULT_COMPETITION';
export const READ_COMPETITION_COUNT = 'READ_COMPETITION_COUNT';
export const CREATE_GAMIFICATION = 'CREATE_GAMIFICATION';
export const DELETE_GAMIFICATION = 'DELETE_GAMIFICATION';
export const CREATE_COMPETITION = 'CREATE_COMPETITION';
export const READ_PANEL_COMPETITION = 'READ_PANEL_COMPETITION';
export const READ_COMPETITION_LAYOUT = 'READ_COMPETITION_LAYOUT';
export const CREATE_BASE_POINT = 'CREATE_BASE_POINT';
export const CREATE_LOCATION_POINT = 'CREATE_LOCATION_POINT';
export const READ_COMPETITION_PAYMENT_COUNT = 'READ_COMPETITION_PAYMENT_COUNT';
export const READ_COMPETITION_CATEGORY_COUNT = 'READ_COMPETITION_CATEGORY_COUNT';
export const READ_USER_NOTIFICATIONS = 'READ_USER_NOTIFICATIONS';
export const CREATE_USER_NOTIFICATIONS = 'CREATE_USER_NOTIFICATIONS';
export const UPDATE_USER_NOTIFICATIONS = 'UPDATE_USER_NOTIFICATIONS';
export const READ_USER_MESSAGES_UNREAD = 'READ_USER_MESSAGES_UNREAD';
export const READ_USER_MESSAGES = 'READ_USER_MESSAGES';
export const UPDATE_USER_MESSAGES = 'UPDATE_USER_MESSAGES';
export const READ_PLAYERS_MEASURES = 'READ_PLAYERS_MEASURES';
export const CREATE_PLAYER_MEASURES = 'CREATE_PLAYER_MEASURES';
export const UPDATE_PLAYER_MEASURES = 'UPDATE_PLAYER_MEASURES';
export const INVALIDATED_PLAYER_MEASURES = 'INVALIDATED_PLAYER_MEASURES';
export const READ_RESULTS_EDIT_STATUS = 'READ_RESULTS_EDIT_STATUS';
export const SET_PLAYERS_REGISTERED = 'SET_PLAYERS_REGISTERED';
export const SET_PLAYERS_PAID = 'SET_PLAYERS_PAID';
export const DECLARE_PLAYERS_REGISTERED = 'DECLARE_PLAYERS_REGISTERED';
export const DECLARE_PLAYERS_PAID = 'DECLARE_PLAYERS_PAID';
export const UPDATE_PLAYERS = 'UPDATE_PLAYERS';
export const READ_COMPETITION_WEATHER = 'READ_COMPETITION_WEATHER';
export const READ_CHRONOMETRY_FTP_FILES = 'READ_CHRONOMETRY_FTP_FILES';
export const READ_USERS_LIST = 'READ_USERS_LIST';
export const READ_PANEL_COMPETITION_PLAYERS = 'READ_PANEL_COMPETITION_PLAYERS';
export const READ_PANEL_COMPETITION_PLAYERS_INPUTS = 'READ_PANEL_COMPETITION_PLAYERS_INPUTS';
export const CREATE_PANEL_COMPETITION_PLAYERS_INPUTS = 'CREATE_PANEL_COMPETITION_PLAYERS_INPUTS';
export const UPDATE_PANEL_COMPETITION_PLAYERS_INPUTS = 'UPDATE_PANEL_COMPETITION_PLAYERS_INPUTS';
export const DELETE_PANEL_COMPETITION_PLAYERS_INPUTS = 'DELETE_PANEL_COMPETITION_PLAYERS_INPUTS';
export const SET_COMPETITION_CHRONO_DEFAULT = 'SET_COMPETITION_CHRONO_DEFAULT';
export const READ_ORGANIZER_SETUP = 'READ_ORGANIZER_SETUP';