<template>
	<div v-if="form && Object.keys(form).length > 0" class="mat40">
		<panel-competition-form-box class="panel-form mab24">
			<div class="col-12 mab24">
				<h2>Konfiguracja własnej domeny</h2>
			</div>
			<div class="row mab16" style="width: 100%;">
				<div class="col-12 col-md-6 ">
					<p class="form-input__label">Wybierz protokół</p>  
					<multiselect v-model="form.protocol" :options="protocols" :searchable="false" :allow-empty="false" :close-on-select="true" :show-labels="false" placeholder="Wybierz protokół" />
				</div>
				<div class="col-12 col-md-6">
					<div class="malr24">
						<p class="form-input__helper">Dodaj własną domenę dla wykazu swoich zawodów. Po dodaniu domeny do ustawień, koniecznie zgłoś tan fakt do naszego działu technicznego pod adres info@dostartu.pl.</p>
					</div>
				</div>
			</div>
			<div class="row mab16" style="width: 100%;">
				<div class="col-12 col-md-6 ">
					<p class="form-input__label">Wpisz domenę</p>                        
					<base-input-text v-model="form.domain" />
				</div>
			</div>
		</panel-competition-form-box>
		<panel-competition-form-box class="panel-form mab24">
			<div class="col-12 mab24">
				<h2>Konfiguracja skrzynki pocztowej</h2>
			</div>
			<div class="row mab16" style="width: 100%;">
				<div class="col-12 col-md-6">
					<div class="row mab16" style="width: 100%;">
						<div class="col-12">
							<p class="form-input__label">Host SMTP (np. mail.dostartu.pl)</p>                        
							<base-input-text v-model="form.host" />
						</div>
					</div>
					<div class="row mab16" style="width: 100%;">
						<div class="col-12">
							<p class="form-input__label">Port SMTP (np. 587)</p>                        
							<base-input-text v-model="form.port" />
						</div>
					</div>
					<div class="row mab16" style="width: 100%;">
						<div class="col-12 ">
							<p class="form-input__label">Nazwa użytkownika (np. info@dostartu.pl)</p>                        
							<base-input-text v-model="form.username" />
						</div>
					</div>
					<div class="row mab16" style="width: 100%;">
						<div class="col-12 ">
							<p class="form-input__label">Hasło użytkownika</p>                        
							<base-input-text v-model="form.password" type="password" />
						</div>
					</div>
					<div class="row mab16" style="width: 100%;">
						<div class="col-12 ">
							<p class="form-input__label">Adres email (np. info@dostartu.pl)</p>                        
							<base-input-text v-model="form.fromemail" />
						</div>
					</div>
					<div class="row mab16" style="width: 100%;">
						<div class="col-12 ">
							<p class="form-input__label">Nazwa wyświetlana (np. DO:ST:AR:TU - zawody biegowe)</p>                        
							<base-input-text v-model="form.fromname" />
						</div>
					</div>
					<div class="row mab16" style="width: 100%;">
						<div class="col-12">
							<p class="form-input__label">Ustawienia zaawansowane:</p>                        
							<panel-input-checkbox 
								v-model="form.keepalive"
								:checkbox-value="true" 
								:name="`input_ids_add_1`"
								variant="white"
								:value="false"
								class="mab8"
							>
								Włącz połączenie stałe (keepalive)
							</panel-input-checkbox>
							<panel-input-checkbox 
								v-model="form.secure"
								:checkbox-value="true" 
								:name="`input_ids_add_1`"
								variant="white"
								:value="false"
								class="mab8"
							>
								Użyj połączenia SSL/TLS (secure) (zalecane)
							</panel-input-checkbox>
							<panel-input-checkbox 
								v-model="form.auth"
								:checkbox-value="true" 
								:name="`input_ids_add_1`"
								variant="white"
								:value="false"
								class="mab8"
							>
								Włącz autoryzację SMTP (auth) (zalecane)
							</panel-input-checkbox>
						</div>
					</div>

				</div>	
				<div class="col-12 col-md-6">
					<div class="malr24">
						<p class="form-input__helper">Ustaw swoje konto e-mail do komunikacji z uczestnikami. Wszystkie dane potrzebne do wypełnienia formularza znajdziesz w panelu konfiguracji skrzynki e-mail u swojego usługodawcy. Dzięki tym zabiegom, w prosty sposób dostosujesz system do własnych potrzeb.</p>
						<br />
						<b class="form-input__helper">UWAGA</b>
						<br /><br />
						<p class="form-input__helper"> Należy zwrócić szczególną uwagę na poprawność ustawionej konfiguracji konta pocztowego. W przypadku błędnej konfiguracji, zawodnicy nie otrzymają powiadomień z istotnymi informacjami (rejestracja, płatności, wyniki)</p>
					</div>
				</div>			
			</div>
		</panel-competition-form-box>
		<div class="col-lg-12 mat16 mab64">
		  <div class="row justify-content-end">
			<div class="col-12 col-lg-4">
			  <base-button @click="saveForm" :disabled="loading" btn1 short>Zapisz</base-button>
			</div>
		  </div>
		</div>
		<panel-competition-form-box class="panel-form mat40 mab24" style="display: none">
			<div class="col-12 mab24">
				<h2>Sprawdzenie konfiguracji konta pocztowego</h2>
			</div>
			<div class="row mab16" style="width: 100%;">
				<div class="col-12 col-md-6 ">
					<base-button btn1 short>Sprawdź połączenie SMTP</base-button>
				</div>
				<div class="col-12 col-md-6 ">
					<div class="malr24">
						<p class="form-input__helper">Aktualnie wiadomości e-mail wysyłane są ze skrzynki operatora z adresu <b>noreply@dostartu.pl</b>.</p>
					</div>
				</div>
			</div>
		</panel-competition-form-box>
        <div class="row" style="margin-bottom: 48px;">
            <div class="col-12 col-lg-4">
				<base-button @click="showPopupForDefault = !showPopupForDefault" btn4 style="color: #B0BEC5;border-color: #B0BEC5;">Przywróć do domyślnych ustawień</base-button>
			</div>
		</div>
		<!-- Popup. -->
		<base-popup v-if="showPopupForDefault">
			<!-- Title. -->
			<template #title>
				Przywróć do domyślnych ustawień
			</template>
			<!-- Subtitle. -->
			<template #subtitle>
				<b>Czy na pewno, chcesz przywrócić ustawienia domyślne?</b>
			</template>
			<!-- Content. -->
			<template #content>
				<div class="mat16">
					Ustawienia własnej domeny i skrzynki pocztowej zostaną <b>usunięte</b>.
				</div>
			</template>
			<!-- Buttons. -->
			<template #buttons>
				<base-button
					class="mar15"
					btn2
					@click="showPopupForDefault = !showPopupForDefault"
				>
					anuluj
				</base-button>
				<base-button
					class="mal15"
					btn1
					@click="
						(showPopupForDefault = !showPopupForDefault),
						defaultEmailDomain()
					"
				>
					Przywróć
				</base-button>
			</template>
		</base-popup> 
	</div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import PanelCompetitionFormBox from '@/components/PanelCompetitionFormBox';
import Multiselect from 'vue-multiselect'
import PanelInputCheckbox from '@/components/PanelInputCheckbox';


export default {
	name: 'PanelAccountSettingsWeb',
	props: {
        id: {
            type: Number,
            default: null,
        },
	},
	components: {
		PanelCompetitionFormBox,
		PanelInputCheckbox,
		Multiselect
	},
	data() {
		return {
			loading: false,
			role: null,
			form: {},
			protocols: ['http://','https://'],
			showPopupForDefault: false,
		}
	},
	computed: {
        ...mapGetters(['user']),
		operatorId() {
			return this.organizerId ? null : this.user.operator
		},
		organizerId() {
			return this.$route.params?.id || this.user.organizer
		},
    },
	created() {
		this.init()
	},
	watch: {
		organizerId() {
			this.init()
		}
	},
	methods: {
		init() {
			if (this.organizerId) {
			this.getRoleData(this.organizerId, 'organizers')
		} else if (this.operatorId) this.getRoleData(this.operatorId, 'operators')
		},
		async getRoleData(id, role) {
			try {
				const response = await axios.get(`/panel/${role}/${id}/emailDomain`);
				this.role = JSON.parse(JSON.stringify(response))
				this.form =  { 
					...response,
					auth: response.auth === null ? false : response.auth,
					keepalive: response.keepalive === null ? false : response.keepalive,
					secure: response.secure === null ? false : response.secure
				}
			} catch (e) {
				this.errors = e;
				return;
			}
		},
		async saveForm() {
            this.loading = true
            const promises = []
			const url = `/panel/emailDomain`
			const payload = {
				...this.form
			}


			const orgPUT = axios({
				method: 'post',
				url,
				data: payload                 
			})
			promises.push(orgPUT)
            await Promise.all(promises).catch(err => {
                this.errors = err;
            });
            this.loading = false
        },
		async defaultEmailDomain() {
			this.loading = true
			let role, id;
			if (this.organizerId) {
				role = 'organizers'
				id = this.organizerId
			} else {
				role = 'operators'
				id = this.operatorId
			}
			try {
				const response = await axios.delete(`/panel/${role}/${id}/emailDomain`);
				this.role = JSON.parse(JSON.stringify(response))
				this.form =  { 
					...response,
					auth: response.auth === null ? false : response.auth,
					keepalive: response.keepalive === null ? false : response.keepalive,
					secure: response.secure === null ? false : response.secure
				}
			} catch (e) {
				this.errors = e;
				return;
			} finally {
				this.loading = false
			}
		}
	}
}
</script>

<style scoped>
.info-box {
    padding: 30px;
	border-radius: 4px;
}
.info-box.info-bg {
	background-color: #fff;
	color: #37474f;
    border-radius: 4px 4px 0px 0px;
}
.info-box.alert-bg {
	background-color: #ffe2e8;
	color: #37474f;
    border-radius:0px 0px 4px 4px;
}
.form-input__label {
	margin-bottom: 6px;
}
.row.unset {
    justify-content: unset;
}
@media (max-width: 991px) {
	.form-input__helper {
		margin: 8px 0;
	}
}
@media (min-width: 992px) {
    .malr8 {
        margin-left: 8px;
    }
    .malr24 {
        margin-left: 24px;
    }
}
</style>	