var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.ready)?_c('div',{staticClass:"container"},[_c('div',{staticClass:"row mat24"},[_c('div',{staticClass:"col-lg-9 col-12 dashboard"},[_c('div',{staticClass:"dashboard-wrapper"},[(_vm.competitionSetting.canInsuranceOn === false)?_c('div',{staticClass:"insurance"},[_vm._m(0),_vm._m(1)]):(_vm.competitionSetting.insurancesOn)?_c('div',{staticClass:"insurance"},[_vm._m(2),_vm._m(3),_c('base-button',{attrs:{"btn2":""},on:{"click":function($event){return _vm.save('off')}}},[_vm._v("Wyłącz ubezpieczenia")])],1):_c('div',{staticClass:"insurance"},[_vm._m(4),_vm._m(5),_c('base-button',{attrs:{"btn1":""},on:{"click":function($event){return _vm.save('on')}}},[_vm._v("Włącz ubezpieczenia")])],1)])]),_vm._m(6)])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"insurance-status"},[_c('img',{attrs:{"src":require("@/assets/images/close-circle.svg"),"alt":""}}),_c('h2',[_vm._v("Ubezpieczenia nieaktywne")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"insurance-content"},[_c('p',[_c('b',[_vm._v("Daj uczestnikom możliwość ubezpieczenia startu w zawodach.")])]),_c('p',[_vm._v("Chcesz uruchomić w swoich zawodach możliwość wykupienia przez uczestników ubezpieczenia rezygnacji z uczestnictwa oraz następstwa nieszczęśliwych wypadków?")]),_c('p',[_vm._v("Dla uczestników to jeden dodatkowy krok w formularzu rejestracyjnym.")]),_c('p',{staticClass:"text-green"},[_vm._v("Włącz płatności automatyczne Dotpay, aby móc uruchomić ubezpieczenia.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"insurance-status active"},[_c('img',{attrs:{"src":require("@/assets/images/checkmark-circle.svg"),"alt":""}}),_c('h2',{staticClass:"text-green"},[_vm._v("Ubezpieczenia aktywne")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"insurance-content"},[_c('p',[_c('b',[_vm._v("Daj uczestnikom możliwość ubezpieczenia startu w zawodach.")])]),_c('p',[_vm._v("Chcesz uruchomić w swoich zawodach możliwość wykupienia przez uczestników ubezpieczenia rezygnacji z uczestnictwa oraz następstwa nieszczęśliwych wypadków?")]),_c('p',[_vm._v("Dla uczestników to jeden dodatkowy krok w formularzu rejestracyjnym.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"insurance-status"},[_c('img',{attrs:{"src":require("@/assets/images/close-circle.svg"),"alt":""}}),_c('h2',[_vm._v("Ubezpieczenia nieaktywne")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"insurance-content"},[_c('p',[_c('b',[_vm._v("Daj uczestnikom możliwość ubezpieczenia startu w zawodach.")])]),_c('p',[_vm._v("Chcesz uruchomić w swoich zawodach możliwość wykupienia przez uczestników ubezpieczenia rezygnacji z uczestnictwa oraz następstwa nieszczęśliwych wypadków?")]),_c('p',[_vm._v("Dla uczestników to jeden dodatkowy krok w formularzu rejestracyjnym.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-lg-3"},[_c('div',{staticClass:"insurance-box"},[_c('p',[_vm._v("Ubezpieczenia dostarcza")]),_c('img',{attrs:{"src":require("@/assets/images/Uniqa_Insurance_Group.png"),"alt":""}})])])
}]

export { render, staticRenderFns }