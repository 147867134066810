export default {
    methods: {
        // Emit 'input' with fileList: [{file}, {file}...].
        async inputFileHandler($event, converter) {
            const { files } = $event.target;
            const fileList = await this.createBinaryFileList(files, converter);
            const uploadedFiles = this.updateWithCurrentFileList(fileList);
            this.$emit('input', uploadedFiles);
        },
        updateWithCurrentFileList(fileList) {
            let fileListWithAlreadyUploaded = [];
            for (const file of fileList) {
                // Prevent upload same files.
                const alreadyUploadedFile = this.value.find((f) => f.name === file.name);
                if (!alreadyUploadedFile) {
                    fileListWithAlreadyUploaded.push(file);
                }
            }
            fileListWithAlreadyUploaded = fileListWithAlreadyUploaded.concat(this.value);
            return fileListWithAlreadyUploaded;
        },
        deleteFile(file) {
            const idx = this.value.findIndex((f) => f.name === file.name);
            if (idx >= 0) {
                this.value.splice(idx, 1);
                this.$emit('input', this.value);
            }
        },
        async createBinaryFileList(files, converter = 'string') {
            const fileList = [];
            const promises = [];
            for (const file of files) {
                promises.push(
                    new Promise((resolve, reject) => {
                        const reader = new FileReader();

                        reader.onload = (e) => {
                            resolve(
                                fileList.push({
                                    name: file.name,
                                    content: e.target.result,
                                    mime: file.type,
                                }),
                            );
                        };
                        reader.onerror = reject;

                        converter === 'base64' ? reader.readAsDataURL(file) : reader.readAsBinaryString(file);
                    }),
                );
            }
            await Promise.all(promises);

            return fileList;
        },
        async createBase64FileList(files) {
            const fileList = [];
            const promises = [];
            for (const file of files) {
                promises.push(
                    new Promise((resolve, reject) => {
                        const reader = new FileReader();

                        reader.onload = (e) => {
                            resolve(
                                fileList.push({
                                    name: file.name,
                                    content: e.target.result.split('data:text/csv;base64,')[1],
                                    mime: file.type,
                                }),
                            );
                        };
                        reader.onerror = reject;

                        reader.readAsDataURL(file);
                    }),
                );
            }
            await Promise.all(promises);

            return fileList;
        },
        // On drop files handler.
        async dropFileHandler($event) {
            const { files } = $event.dataTransfer;
            const fileList = await this.createBinaryFileList(files);
            const uploadedFiles = this.updateWithCurrentFileList(fileList);
            this.$emit('input', uploadedFiles);
        },
        updateError() {
            this.$emit('update:error', null);
        },
    },
};
