var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.ready)?_c('div',{staticClass:"container map-container",class:{ 'map-container--zen-mode': _vm.isZenMode }},[_c('div',{staticClass:"row",class:{ h100: _vm.isZenMode }},[_c('div',{class:_vm.isZenMode
                        ? 'col-lg-3 map-container__sidebar--zen-mode'
                        : 'col-lg-3'},[_c('div',{staticClass:"map-container__sidebar"},[_c('div',[_c('h2',[_vm._v("Trasa i punkty na trasie")]),_c('p',{staticClass:"mat16"},[_vm._v(" Klasyfikacja ")]),_c('base-input-select',{staticClass:"mat8 map-container__sidebar-select",attrs:{"options":_vm.classifications,"label-select":"namePl","label":"Klasyfikacja","error":_vm.errors.classification},on:{"update:error":function($event){return _vm.$set(_vm.errors, "classification", $event)}},model:{value:(_vm.selectedClassification),callback:function ($$v) {_vm.selectedClassification=$$v},expression:"selectedClassification"}}),_c('p',{staticClass:"mat16"},[_vm._v(" Dodaj trasę w pliku .gpx ")]),_c('base-input-file',{key:_vm.inputFileKey,staticClass:"mat8",class:{
                                'map-container__sidebar-file': !_vm.isZenMode,
                            },attrs:{"hint":"Wybierz plik","disabled":_vm.disabledInput},on:{"input":function($event){return _vm.createGpx()},"click":function($event){_vm.disabledInput
                                    ? (_vm.errors.classification =
                                        'Wybierz klasyfikacje')
                                    : ''}},model:{value:(_vm.trackFileList),callback:function ($$v) {_vm.trackFileList=$$v},expression:"trackFileList"}}),_c('base-button',{staticClass:"mat48",attrs:{"btn2":"","icon":"fas fa-plus"},on:{"click":function($event){return _vm.addPoint()}}},[_vm._v(" Dodaj punkt ")])],1),_vm._v(" Tracking obłsugiwany przez: "),_c('img',{staticClass:"img-fluid mal24",staticStyle:{"max-width":"200px"},attrs:{"src":require("@/assets/images/logo-ap-white.png")},on:{"click":function($event){return _vm.$router.push({
                                    path: `http://tracking.activeprogress.eu`,
                                    tab: true,
                                })}}}),_c('base-button',{staticClass:"mat16",attrs:{"btn2":""},on:{"click":function($event){return _vm.$router.push({
                                path: `http://tracking.activeprogress.eu/${_vm.competition.name}-v${_vm.competition.id}`,
                                tab: true,
                            })}}},[_vm._v(" Twój tracking GPS ")])],1)]),_c('div',{class:_vm.isZenMode
                        ? 'col-lg-9 map-container__map--zen-mode'
                        : 'col-lg-9'},[_c('map-competition',{attrs:{"competition":_vm.competition,"classifications":_vm.classifications,"selected-classification":_vm.selectedClassification},on:{"update:selectedClassification":function($event){_vm.selectedClassification=$event},"update:selected-classification":function($event){_vm.selectedClassification=$event},"delete-point":_vm.onDeletePointClick,"ready":function($event){_vm.map = $event}}})],1),(_vm.showPopupForRemove)?_c('base-popup',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Usuń punkt ")]},proxy:true},{key:"subtitle",fn:function(){return [_c('b',[_vm._v("Czy na pewno, chcesz usunąć wybrany punkt?")])]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"mat16"},[_vm._v(" Zostanie on usunięty z "),_c('b',[_vm._v("wszystkich klasyfikacji")]),_vm._v(" . Aby nie był wyświetlany w tej klasyfikacji, nie wybieraj żadnej kategorii w punkcie (nie wybrane Start, Meta, Pomiar, Punkt żywieniowy) ")])]},proxy:true},{key:"buttons",fn:function(){return [_c('base-button',{staticClass:"mar15",attrs:{"btn2":""},on:{"click":function($event){(_vm.showPopupForRemove = !_vm.showPopupForRemove),
                            (_vm.pointToDelete = null)}}},[_vm._v(" anuluj ")]),_c('base-button',{staticClass:"mal15",attrs:{"btn1":""},on:{"click":function($event){(_vm.showPopupForRemove = !_vm.showPopupForRemove),
                            _vm.deletePoint()}}},[_vm._v(" Usuń punkt ")])]},proxy:true}],null,false,127542549)}):_vm._e()],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }