<template>
    <div class="row flex-nowrap nav-bar">
        <!-- Top buttons. -->
        <div class="col-lg-7">
            <base-button-group
                horizontal
                class="mar30 h100"
            >
                <base-button
                    btn11
                    badge="far fa-home"
                    class="competition-topbar__btn competition-topbar__btn--1"
                    :pressed="$route.name === 'panel-competition-dashboard'"
                    @click="
                        $router.push({
                            name: 'panel-competition-dashboard',
                            params: competition,
                            alias: true,
                        })
                    "
                />
                <base-button
                    btn11
                    badge="fas fa-tachometer-alt"
                    class="nowrap competition-topbar__btn competition-topbar__btn--2"
                    @click="
                        $router.push({
                            name: 'panel-competition-setting-info',
                            params: competition,
                            alias: true,
                        })
                    "
                >
                    Ustawienia zawodów
                </base-button>
                <base-button
                    btn11
                    badge="far fa-users"
                    class="nowrap competition-topbar__btn competition-topbar__btn--3"
                    @click="
                        $router.push({
                            name: 'panel-competition-list-new',
                            params: competition,
                            alias: true,
                        })
                    "
                >
                    Lista startowa
                </base-button>
                <base-button
                    v-if="competition.isRemote === false"
                    btn11
                    badge="fas fa-list-ol"
                    class="nowrap competition-topbar__btn competition-topbar__btn--4"
                    @click="
                        $router.push({
                            name: 'panel-competition-result',
                            params: competition,
                            alias: true,
                        })
                    "
                >
                    Wyniki
                    <!-- Results remote  -->
                </base-button>
                <base-button
                    v-else
                    btn11
                    badge="fas fa-list-ol"
                    class="nowrap competition-topbar__btn competition-topbar__btn--4"
                    @click="
                        $router.push({
                            name: 'panel-competition-result-legacy',
                            params: competition,
                            alias: true,
                        })
                    "
                >
                    Wyniki
                </base-button>
            </base-button-group>
        </div>

        <!-- Tools button. -->
        <div class="col-lg-2">
            <panel-menu-button class="mar30">
                <base-button
                    btn11
                    icon="fas fa-caret-down"
                    :class="{
                        'menu-btn': true,
                        'menu-btn--active': isMenuActive,
                    }"
                >
                    Narzędzia
                </base-button>
                <template v-slot:menu>
                    <base-button
                        btn11
                        badge="far fa-clock"
                        @click="
                            $router.push({
                                name: 'panel-competition-stoper',
                                params: competition,
                                alias: true,
                            })
                        "
                    >
                        Stoper
                    </base-button>
                    <base-button
                        btn11
                        badge="fas fa-envelope"
                        @click="
                            $router.push({
                                name: 'panel-competition-mail',
                                params: competition,
                                alias: true,
                            })
                        "
                    >
                        Wiadomości
                    </base-button>
                    <base-button
                        btn11
                        badge="far fa-shopping-bag"
                        @click="
                            $router.push({
                                name: 'panel-competition-shop',
                                params: competition,
                                alias: true,
                            })
                        "
                    >
                        Sklep
                    </base-button>
                    <base-button
                        btn11
                        badge="fas fa-shield-alt"
                        @click="
                            $router.push({
                                name: 'panel-competition-insurance',
                                params: competition,
                                alias: true,
                            })
                        "
                    >
                        Ubezpieczenia
                    </base-button>
                    <base-button
                        btn11
                        badge="far fa-language"
                        @click="
                            $router.push({
                                name: 'panel-competition-languages',
                                params: competition,
                                alias: true,
                            })
                        "
                    >
                        Wersje językowe
                    </base-button>
                    <base-button
                        btn11
                        badge="far fa-map"
                        @click="
                            $router.push({
                                name: 'panel-competition-map',
                                params: competition,
                                alias: true,
                            })
                        "
                    >
                        Trasa i punkty
                    </base-button>
                </template>
            </panel-menu-button>
        </div>

        <!-- Competition registration status. -->
        <div
            v-if="!isResultsStatuses"
            class="col-lg-3 d-flex"
        >
            <base-button
                btn9
                class="status-btn mar3"
                :class="{
                    'status-btn--negative':
                        [STATUSES.close, STATUSES.info].includes(competition.status)}"
                @click="updateCompetitionStatus(STATUSES.close)"
            >
                Zamknięta
            </base-button>
            <base-button
                btn9
                class="status-btn"
                :class="{
                    'status-btn--positive': competition.status == STATUSES.open,
                }"
                @click="checkOrganizer"
            >
                Otwarta
            </base-button>
        </div>
		<!-- @click="updateCompetitionStatus(STATUSES.open)" -->
        <!-- Competition results status. -->
        <div
            v-else
            class="col-lg-3 d-flex"
        >
            <base-button
                v-if="competition.status <= STATUSES.live"
                btn9
                class="mar4 status-result"
                :class="{
                    'status-btn--positive': competition.status == STATUSES.live,
                }"
                @click="updateCompetitionStatus(STATUSES.live)"
            >
                Live
            </base-button>
            <base-button
                btn9
                class="mar4 status-result"
                :class="{
                    'status-btn--positive':
                        competition.status == STATUSES.unofficial,
                }"
                @click="updateCompetitionStatus(STATUSES.unofficial)"
            >
                Nieoficjalne
            </base-button>
            <base-button
                btn9
                class="status-result"
                :class="{
                    'status-btn--positive':
                        competition.status == STATUSES.official,
                }"
                @click="updateCompetitionStatus(STATUSES.official)"
            >
                Oficjalne
            </base-button>
        </div>
		<CheckOrganizerSetup 
			v-if="showOrganizerModal"
			:id="organizerId"
			@change="updateCompetitionStatus(STATUSES.open)" 
			@close="showOrganizerModal = false"
		/>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { UPDATE_COMPETITION } from '@/store/actions.type';
import { STATUSES } from '@/config';
import PanelMenuButton from './PanelMenuButton';
import CheckOrganizerSetup from './CheckOrganizerSetup.vue';

export default {
    components: {
        PanelMenuButton,
		CheckOrganizerSetup
    },
    data() {
        return {
            STATUSES,
			showOrganizerModal: false
        };
    },
    computed: {
        ...mapGetters(['competitions']),
        competition() {
            return this.competitions[this.$route.params.id];
        },
		organizerId() {
			return this.competition.organizer || null
		},		
        ready() {
            return this.competition !== undefined;
        },
        isMenuActive() {
            return [
                'panel-competition-stoper',
                'panel-competition-map',
            ].includes(this.$route.name);
        },
        isResultsStatuses() {
            return (
                (this.$route.name === 'panel-competition-stoper'
                    && this.competition.status <= 4)
                || (this.$route.name === 'panel-competition-result'
                    && this.competition.status <= 4)
                || this.competition.status >= 4
            );
        },
    },
    methods: {
        ...mapActions([UPDATE_COMPETITION]),
        async updateCompetitionStatus(status) {
            await this.UPDATE_COMPETITION({
                ...this.competition,
                status,
            });
        },
		checkOrganizer() {
			this.showOrganizerModal = true
		}
    },
};
</script>

<style scoped>
.nav-bar {
    height: 40px;
}
.status-result {
    padding: 10px 17px;
    width: 100%;
}
.status-btn {
    width: 133px;
    line-height: 1.43;
    width: 100%;
}

.status-btn--negative {
    background-color: var(--color-lipstick);
}

.status-btn--positive {
    background-color: var(--color-dark-pastel-green);
}

.status-btn:hover {
    opacity: 1 !important;
}

.status-btn:hover:not(.status-btn--negative):not(.status-btn--positive) {
    background-color: var(--color-bluish-grey);
}
.status-result:hover:not(.status-btn--negative):not(.status-btn--positive) {
    background-color: var(--color-bluish-grey);
}

.status-btn.btn--9::after {
    display: none;
}
.status-result.btn--9::after {
    display: none;
}
/* @TODO */
.competition-topbar__btn {
    padding: 0 15px;
    height: 100%;
}
.competition-topbar__btn--1 {
    width: 10%;
}
.competition-topbar__btn--2 {
    width: 35%;
}
.competition-topbar__btn--3 {
    width: 30%;
}
.competition-topbar__btn--4 {
    width: 25%;
}
</style>
