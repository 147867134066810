var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"overflow":"hidden"}},[(!_vm.ready)?_c('main',[_c('div',{staticClass:"d-flex flex-column mat24"},[_c('h2',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.say.results_are_loading)+" ")]),_vm._m(0)])]):_c('div',[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row mat16"},[_c('div',{staticClass:"col-12 col-md-8"},[_c('h2',[_vm._v(_vm._s(_vm.competition.name)+" - "+_vm._s(_vm.classification.namePl))])]),_c('div',{staticClass:"col-12 col-md-4 d-flex justify-content-end"},[_c('base-button',{attrs:{"btn9":"","icon":_vm.isZenMode
                    ? 'fas fa-compress-alt'
                    : 'fas fa-expand-alt'},on:{"click":function($event){return _vm.SET_ZEN_MODE(!_vm.isZenMode)}}})],1)])]),_c('div',{staticClass:"container-result"},[(_vm.items.length)?_c('base-table',{staticClass:"table-list",attrs:{"headers":_vm.headers,"items":_vm.items,"sortColumnDefault":"0","sortReverseDefault":false},scopedSlots:_vm._u([{key:"header",fn:function({ sort }){return [_c('tr',{staticClass:"table-list__row bg--dark"},_vm._l((_vm.headers),function(header,column){return _c('th',{key:column,staticClass:"table-list__header",class:{
                          'table-list__header--second-cell':
                              column === 1,
                          'table-list__header--first-cell':
                              column === 0,
                          'd-none':
                              column === 1 &&
                              _vm.competition.isRemote &&
                              _vm.competition.id !== 4877,
                      },on:{"click":function($event){return sort(column)}}},[(
                              !_vm.isSplitTime &&
                                  column === _vm.headers.length - 2 &&
                                  !_vm.competition.isRemote
                          )?[_vm._v(" "+_vm._s(header.text)+"(lap) ")]:[_vm._v(" "+_vm._s(header.text)+" ")]],2)}),0)]}},{key:"body",fn:function({ displayedItems }){return _vm._l((displayedItems),function(item){return _c('tr',{key:item.id,staticClass:"table-list__row",class:{ 'hidden' : item.position === null }},_vm._l((_vm.headers),function(header,column){return _c('td',{key:column,staticClass:"table-list__data",class:{
                          'table-list__data--second-cell':
                              column === 1,
                          'table-list__data--first-cell':
                              column === 0,
                          'd-none':
                              column === 1 &&
                              _vm.competition.isRemote &&
                              _vm.competition.id !== 4877,
                          'table-list__data--right-border':
                              column === 0 &&
                              _vm.competition.isRemote &&
                              _vm.competition.id !== 4877,
                          'table-list__data--padding':
                              header.value === 'type',
                          'align-right':
                              header.value === 'isDeclaredTime',
                      }},[(
                              header.value === 'isDeclaredTime' &&
                                  item.isDeclaredTime
                          )?_c('p',{staticClass:"table-list__data--info-time"},[_vm._v(" "+_vm._s(_vm.say.declared_time)+" ")]):(
                              header.value ===
                                  'isDeclaredDistance' &&
                                  item.isDeclaredDistance
                          )?_c('p',{staticClass:"table-list__data--info-time"},[_vm._v(" "+_vm._s(_vm.say.declared_distance)+" ")]):(
                              (header.value ===
                                  'isDeclaredTime' &&
                                  !item.isDeclaredTime) ||
                                  (header.value ===
                                      'isDeclaredDistance' &&
                                      !item.isDeclaredDistance)
                          )?_c('p'):(
                              header.value === 'distance' &&
                                  item[header.value]
                          )?[_vm._v(" "+_vm._s(`${item[header.value]} km`)+" ")]:(column === 0)?[_vm._v(" "+_vm._s(item.position)+" ")]:[_vm._v(" "+_vm._s(item[header.value])+" ")]],2)}),0)})}}],null,false,4187085555)}):_vm._e()],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mat16 lds-ellipsis lds-ellipsis--center"},[_c('div'),_c('div'),_c('div')])
}]

export { render, staticRenderFns }