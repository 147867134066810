import { render, staticRenderFns } from "./BaseInputSelect.vue?vue&type=template&id=04626c20&scoped=true&"
import script from "./BaseInputSelect.vue?vue&type=script&lang=js&"
export * from "./BaseInputSelect.vue?vue&type=script&lang=js&"
import style0 from "vue-select/dist/vue-select.css?vue&type=style&index=0&prod&lang=css&"
import style1 from "./BaseInputSelect.vue?vue&type=style&index=1&id=04626c20&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04626c20",
  null
  
)

export default component.exports