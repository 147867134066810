var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"row organizer-hint"},[_c('div',{staticClass:"col-12"},[_c('h1',{staticClass:"organizer-hint__title"},[_vm._v(" Witaj w panelu organizatora zawodów! ")]),_c('base-button',{staticClass:"organizer-hint__button",attrs:{"btn1":""},on:{"click":function($event){return _vm.createDefaultCompetition()}}},[_vm._v(" Utwórz przykładowe zawody ")]),_c('p',{staticClass:"organizer-hint__or"},[_vm._v(" lub ")]),_c('base-button',{staticClass:"organizer-hint__button organizer-hint__button--self",attrs:{"btn4":""},on:{"click":function($event){return _vm.$router.push({
                        name: 'panel-create-competition',
                        params: {
                            organizerId: _vm.organizerId,
                        },
                        alias: true,
                    })}}},[_vm._v(" Dodaj swoje zawody ")])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }