var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.ready)?_c('main',[_c('div',{staticClass:"d-flex flex-column mat24"},[_c('h2',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.say.results_are_loading)+" ")]),_vm._m(0)])]):_vm._e(),(_vm.ready)?_c('main',[_c('div',{staticClass:"list-header"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-12 col-lg-2 mar-auto"},[_c('h1',{staticClass:"l-short"},[_vm._v(" "+_vm._s(_vm.say.results)+" ")]),_c('p',[_vm._v(_vm._s(_vm.classification.namePl))])]),(_vm.ageCategories.length > 1)?_c('div',{staticClass:"col-12 col-lg-3"},[_c('div',{staticClass:"list-header__select"},[_c('base-input-select',{attrs:{"options":_vm.ageCategories,"label":_vm.say.category,"label-select":"namePl"},model:{value:(_vm.selectedAgeCategory),callback:function ($$v) {_vm.selectedAgeCategory=$$v},expression:"selectedAgeCategory"}})],1)]):_vm._e(),(_vm.specialCategories.length > 0)?_c('div',{staticClass:"col-12 col-lg-3"},[_c('div',{staticClass:"list-header__select"},[_c('base-input-select',{attrs:{"options":_vm.specialCategories,"label":_vm.say.special_categories,"label-select":"namePl"},model:{value:(_vm.selectedSpecialCategory),callback:function ($$v) {_vm.selectedSpecialCategory=$$v},expression:"selectedSpecialCategory"}})],1)]):_vm._e(),_c('div',{staticClass:"col-12",class:{
                            'col-lg-2': Object.values(_vm.splitTime).length,
                            'col-lg-3': !Object.values(_vm.splitTime).length,
                        }},[_c('div',{staticClass:"list-header__search-input"},[_c('base-input-text-with-icon',{attrs:{"label":_vm.say.search,"icon":"fas fa-search","badge":"fas fa-times"},on:{"icon-click":function($event){_vm.search = ''}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]),(Object.values(_vm.splitTime).length)?_c('div',{staticClass:"col-lg-1 list-header--right"},[_c('div',{staticClass:"list-header__switch row"},[_c('div',{staticClass:"list-header__switch-btn col-5",class:{
                                    'list-header__switch-btn--right': _vm.isSplitTime,
                                },on:{"click":function($event){_vm.isSplitTime = !_vm.isSplitTime}}},[_vm._v(" "+_vm._s(_vm.isSplitTime ? "SPLIT" : "LAP")+" ")])])]):_vm._e()])])]),_c('div',{staticClass:"palr16"},[(_vm.competition.isRemote)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"bg--green results__remote"},[_c('div',{staticClass:"row justify-content-start"},[_vm._m(1),_c('div',{staticClass:"col-12 col-md-7 col-lg-5 results__remote-description"},[_c('div',{staticClass:"results__remote-header"},[_vm._v(" "+_vm._s(_vm.say.remote_competition)+" ")]),_c('div',[_vm._v(_vm._s(_vm.say.add_your_result_hint))]),(
                                        _vm.canDeclareResults(_vm.user, _vm.competition)
                                    )?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-3 mat24"},[_c('base-button',{staticClass:"nowrap",attrs:{"btn4":""},on:{"click":function($event){return _vm.declareResult()}}},[_vm._v(" "+_vm._s(_vm.say.add_your_result)+" ")])],1)]):_vm._e()])])])])]):_vm._e(),(!_vm.items.length)?_c('div',{staticClass:"row mat16"},[_c('div',{staticClass:"col-12 text-center"},[_vm._v(" "+_vm._s(_vm.say.no_results_yet)+" ")])]):_vm._e(),_c('div',{staticClass:"container-result"},[(_vm.items.length)?_c('base-table',{staticClass:"table-list",attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search},scopedSlots:_vm._u([{key:"header",fn:function({ sort }){return [_c('tr',{staticClass:"table-list__row bg--white"},_vm._l((_vm.headers),function(header,column){return _c('th',{key:column,staticClass:"table-list__header",class:{
                                    'table-list__header--second-cell':
                                        column === 1,
                                    'table-list__header--first-cell':
                                        column === 0,
                                    'd-none':
                                        column === 1 &&
                                        _vm.competition.isRemote &&
                                        _vm.competition.id !== 4877 ||
                                        header.value === 'meta_summary' && _vm.isSplitTime,
                                },on:{"click":function($event){return sort(column)}}},[(
                                        !_vm.isSplitTime &&
                                            column === _vm.headers.length - 3 &&
                                            !_vm.competition.isRemote
                                    )?[_vm._v(" LAP ")]:[_vm._v(" "+_vm._s(header.text)+" ")]],2)}),0)]}},{key:"body",fn:function({ displayedItems }){return _vm._l((displayedItems),function(item){return _c('tr',{key:item.id,staticClass:"table-list__row"},_vm._l((_vm.headers),function(header,column){return _c('td',{key:column,staticClass:"table-list__data",class:{
                                    'table-list__data--second-cell':
                                        column === 1,
                                    'table-list__data--first-cell':
                                        column === 0,
                                    'd-none':
                                        column === 1 &&
                                        _vm.competition.isRemote &&
                                        _vm.competition.id !== 4877 ||
                                        header.value === 'meta_summary' && _vm.isSplitTime,
                                    'table-list__data--right-border':
                                        column === 0 &&
                                        _vm.competition.isRemote &&
                                        _vm.competition.id !== 4877,
                                        
                                    'table-list__data--padding':
                                        header.value === 'type',
                                    'align-right':
                                        header.value === 'isDeclaredTime',
                                }},[(header.value === 'meta_summary')?_c('p',[_vm._v(" "+_vm._s(item.time)+" ")]):(
                                        header.value === 'isDeclaredTime' &&
                                            item.isDeclaredTime
                                    )?_c('p',{staticClass:"table-list__data--info-time"},[_vm._v(" "+_vm._s(_vm.say.declared_time)+" ")]):(
                                        header.value ===
                                            'isDeclaredDistance' &&
                                            item.isDeclaredDistance
                                    )?_c('p',{staticClass:"table-list__data--info-time"},[_vm._v(" "+_vm._s(_vm.say.declared_distance)+" ")]):(
                                        (header.value ===
                                            'isDeclaredTime' &&
                                            !item.isDeclaredTime) ||
                                            (header.value ===
                                                'isDeclaredDistance' &&
                                                !item.isDeclaredDistance)
                                    )?_c('p'):(
                                        header.value === 'distance' &&
                                            item[header.value]
                                    )?[_vm._v(" "+_vm._s(`${item[header.value]} km`)+" ")]:[_vm._v(" "+_vm._s(item[header.value])+" ")]],2)}),0)})}},{key:"no-results",fn:function({ search: term }){return [_c('p',{staticClass:"bg--gray pa16"},[_vm._v(" "+_vm._s(_vm.say.no_search_results)+" "),_c('b',[_vm._v(_vm._s(term))])])]}}],null,false,3805028916)}):_vm._e()],1)])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mat16 lds-ellipsis lds-ellipsis--center"},[_c('div'),_c('div'),_c('div')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-md-5 d-flex justify-content-center align-items-center"},[_c('img',{staticClass:"results__remote-img",attrs:{"src":require("@/assets/images/illustration-remote.svg"),"alt":""}})])
}]

export { render, staticRenderFns }