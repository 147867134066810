var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-dark",class:{
        'bg-dark--darken': [
            'panel-gate',
            'panel-competition-stoper',
        ].includes(_vm.$route.name),
    }},[(!_vm.isOnline)?_c('div',{staticClass:"network-info"},[_vm._m(0)]):_vm._e(),_c('div',{attrs:{"id":"layoutBack"}},[_c('base-notification-global'),(_vm.$route.name !== 'panel-gate')?_c('layout-back-header',{class:{
                'd-none': _vm.isZenMode,
                'none-mobile': _vm.$route.name === 'panel-competition-stoper',
            }}):_vm._e(),(_vm.isMobile && _vm.isStoper)?_c('div',{staticClass:"stoper-header"},[_c('img',{staticClass:"stoper-header__logo",attrs:{"src":require("@/assets/images/logo-dostartu-white.svg"),"alt":"Logo dostartu"}}),_c('img',{staticClass:"stoper-header__menu",attrs:{"src":require("@/assets/images/icons/home.svg"),"alt":"home logo"},on:{"click":function($event){return _vm.TOGGLE_NAV()}}})]):_vm._e(),(_vm.isNavActive)?_c('panel-competition-nav-mobile'):_vm._e(),_c('router-view',{class:{
                relative: !_vm.$router.matched(
                    ['panel-competition-stoper', 'panel-competition-map'],
                    _vm.$route,
                ),
            }})],1),(_vm.$route.name !== 'panel-gate')?_c('layout-back-footer',{staticClass:"panel-footer"}):_vm._e(),_c('Communicator',{directives:[{name:"show",rawName:"v-show",value:(_vm.showCommunicator),expression:"showCommunicator"}]})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('p',{staticClass:"align-center"},[_vm._v(" Jesteś w trybie offline. ")])])])
}]

export { render, staticRenderFns }