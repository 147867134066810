var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"stoper-prices"},[_c('div',{staticClass:"stoper-price__left-background",style:({ width: _vm.widthOfAdditionalBackground + 'px' })}),_c('div',{staticClass:"stoper-price__right-background",style:({ width: _vm.widthOfAdditionalBackground + 'px' })}),_c('div',{ref:"prices",staticClass:"container"},[_c('div',{staticClass:"row align-items-end"},[_c('div',{staticClass:"col-lg-3"},[_c('div',{staticClass:"mar30"},[_c('h2',[_vm._v("Koszt pomiaru czasu stoperem")]),_c('p',{staticClass:"stoper-prices__hint"},[_vm._v(" Jeśli do Twoich zawodów zapisało się ponad 50 uczestników, otrzymasz od nas fakturę VAT na kwotę uzależnioną do liczby uczesników w zawodach. ")]),_vm._m(0),(_vm.competitionPlayerCount)?_c('div',{staticClass:"stoper-prices__players row"},[_c('h1',{staticClass:"stoper-prices__players-value"},[_vm._v(" "+_vm._s(_vm.playersCount)+" ")]),_vm._m(1)]):_vm._e()])]),_c('div',{staticClass:"col-lg-9"},[_c('div',{staticClass:"row h100"},[_c('div',{staticClass:"col-lg-4"},[_c('div',{staticClass:"stoper-prices__box",class:{
                                'stoper-prices__box--active':
                                    _vm.playersCount <= 50,
                            }},[_vm._m(2),_c('h2',{staticClass:"stoper-prices__box-price"},[_vm._v(" 0 zł ")])])]),_c('div',{staticClass:"col-lg-4"},[_c('div',{staticClass:"stoper-prices__box",class:{
                                'stoper-prices__box--active':
                                    _vm.playersCount > 50 &&
                                    _vm.playersCount <= 100,
                            }},[_vm._m(3),_c('h2',{staticClass:"stoper-prices__box-price"},[_vm._v(" 250 zł ")]),_c('p',{staticClass:"text-center mat4"},[_vm._v(" za zawody ")])])]),_c('div',{staticClass:"col-lg-4"},[_c('div',{staticClass:"stoper-prices__box",class:{
                                'stoper-prices__box--active':
                                    _vm.playersCount > 100,
                            }},[_vm._m(4),_c('h2',{staticClass:"stoper-prices__box-price"},[_vm._v(" 2,50 zł ")]),_c('p',{staticClass:"text-center mat4"},[_vm._v(" za uczestnika ")])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"stoper-prices__hint"},[_vm._v(" W zawodach do 50 uczestników pomiar stoperem jest "),_c('span',{staticClass:"positive"},[_vm._v("DARMOWY")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"stoper-prices__players-hint"},[_c('p',[_vm._v(" Aktualna liczba zarejestrowanych uczestników ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"stoper-prices__box-hint"},[_c('p',[_vm._v("Zawody sportowe")]),_c('h3',{staticClass:"f36"},[_vm._v(" do 50 ")]),_c('p',[_vm._v("uczestników")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"stoper-prices__box-hint"},[_c('p',[_vm._v("Zawody sportowe")]),_c('h3',{staticClass:"f36"},[_vm._v(" do 100 ")]),_c('p',[_vm._v("uczestników")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"stoper-prices__box-hint"},[_c('p',[_vm._v("Zawody sportowe")]),_c('h3',{staticClass:"f36"},[_vm._v(" ponad 100 ")]),_c('p',[_vm._v("uczestników")])])
}]

export { render, staticRenderFns }