var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"border-box",class:[
        _vm.horizontal
            ? 'border-box--horizontal d-flex'
            : 'border-box--vertical',
        {   
            'noBorder': !_vm.border,
            'shadow': _vm.shadow,
        },
    ]},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }