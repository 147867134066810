var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.ready)?_c('div',{staticClass:"row",class:[_vm.isZenMode ? 'mat40-desktop' : 'mat56-desktop']},[(!_vm.isPanelGate)?_c('div',{staticClass:"col-12 col-lg-3"},[_c('div',{staticClass:"controls"},[_c('base-input-select',{staticClass:"gate-select",attrs:{"label-select":"name","clearable":false,"options":_vm.stopers,"value":_vm.stoperGate},on:{"input":function($event){return _vm.SELECT_STOPER_GATE($event)}}}),_c('div',{staticClass:"row controls__buttons"},[_c('base-button',{attrs:{"btn9":"","icon":_vm.isZenMode
                            ? 'fas fa-compress-alt'
                            : 'fas fa-expand-alt'},on:{"click":function($event){_vm.SET_ZEN_MODE(!_vm.isZenMode),
                        _vm.events.$emit('resize-stoper')}}}),_c('base-button',{staticClass:"setting-btn",attrs:{"btn9":"","icon":"fas fa-cog"},on:{"click":function($event){return _vm.$router.push({
                            name: 'panel-competition-setting-stoper',
                            params: _vm.competitions[_vm.stopers[0].competition],
                            alias: true,
                        })}}},[_vm._v(" Konfiguracja ")])],1)],1)]):_vm._e(),(!_vm.stoperStopped)?_c('div',{staticClass:"col-12",class:{ 'col-lg-9': !_vm.isPanelGate, 'col-lg-12': _vm.isPanelGate }},[_c('div',{staticClass:"row",class:{ marl15: _vm.isPanelGate }},[_c('div',{staticClass:"col-lg-6 col-12 time--mobile relative"},[_c('div',{class:{ 'justify-content-center': _vm.isPanelGate }},[(_vm.stoperStarted)?_c('div',{staticClass:"mat15 d-flex"},[_c('div',{staticClass:"time",class:{ 'time--without-margin': _vm.isPanelGate }},[_vm._v(" "+_vm._s(_vm._f("asTimer")(_vm.stoperDuration))+". "),_c('div',{staticClass:"time__milliseconds"},[_c('div',{staticClass:"time__milliseconds-box time__milliseconds-box--first"},_vm._l((10),function(i){return _c('span',{key:i,staticClass:"time__milliseconds-value"},[_vm._v(_vm._s(i - 1))])}),0),_c('div',{staticClass:"time__milliseconds-box time__milliseconds-box--second"},_vm._l((10),function(i){return _c('span',{key:i,staticClass:"time__milliseconds-value"},[_vm._v(_vm._s(i - 1))])}),0),_c('div',{staticClass:"time__milliseconds-box time__milliseconds-box--third"},_vm._l((10),function(i){return _c('span',{key:i,staticClass:"time__milliseconds-value"},[_vm._v(_vm._s(i - 1))])}),0)])]),(!_vm.isPanelGate)?_c('span',{staticClass:"reset-button",on:{"click":function($event){_vm.showPopup = !_vm.showPopup}}},[_vm._v(" Resetuj ")]):_vm._e()]):_c('p',{staticClass:"time disabled",class:{
                            'time--without-margin': _vm.isPanelGate,
                            'time--hide': !_vm.isPanelGate && !_vm.isMobileView,
                            mat15: _vm.isPanelGate,
                        }},[_vm._v(" 00:00:00.0 ")])])]),_c('div',{staticClass:"none-mobile",class:{
                    'col-lg-12': !_vm.stoperStarted && !_vm.isPanelGate,
                    'col-lg-6': _vm.stoperStarted || _vm.isPanelGate,
                }},[(_vm.stoperStarted || _vm.isPanelGate)?[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-9"},[_c('base-input-multiple',{attrs:{"value":_vm.numbers,"input":_vm.number},on:{"change":function($event){_vm.numbers = $event},"input":function($event){_vm.number = $event},"enter":function($event){return _vm.ADD_STOPER_MEASURE_NUMBERS()}}})],1),_c('div',{staticClass:"col-lg-3"},[_c('base-button',{staticClass:"stop-btn",attrs:{"btn1":"","disabled":!_vm.stoperStarted},on:{"click":function($event){return _vm.ADD_STOPER_MEASURE_NUMBERS()}}},[_vm._v(" Stop "),_c('img',{staticClass:"mal12",attrs:{"src":require("@/assets/images/icon-enter.svg")}})])],1)]),_vm._m(0)]:_c('div',{staticClass:"none-mobile bg-slate justify-content-center"},[_c('base-button',{staticClass:"col-lg-6",attrs:{"btn1":""},on:{"click":_vm.startStoper}},[_vm._v(" start ")])],1)],2)])]):_c('div',{staticClass:"col-12 d-flex",class:{ 'col-lg-9': !_vm.isPanelGate, 'col-lg-12': _vm.isPanelGate }},[_c('span',{staticClass:"stoper-end"},[_vm._v("Zawody zakończone")])]),(_vm.showPopup)?_c('base-popup',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Resetowanie stopera ")]},proxy:true},{key:"subtitle",fn:function(){return [_c('b',[_vm._v("Czy na pewno, chcesz zresetować stoper?")])]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"mat16"},[_vm._v(" Tej czynności nie będzie można cofnąć. ")])]},proxy:true},{key:"buttons",fn:function(){return [_c('base-button',{staticClass:"mar15",attrs:{"btn2":""},on:{"click":function($event){_vm.showPopup = !_vm.showPopup}}},[_vm._v(" anuluj ")]),_c('base-button',{staticClass:"mal15",attrs:{"btn1":""},on:{"click":function($event){_vm.RESET_STOPER(), (_vm.showPopup = !_vm.showPopup)}}},[_vm._v(" Resetuj ")])]},proxy:true}],null,false,3123005440)}):_vm._e()],1):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mat16"},[_c('p',{staticClass:"hint"},[_vm._v(" Spacja dodaje numer do kolejki, Enter zatrzymuje czas. ")])])
}]

export { render, staticRenderFns }