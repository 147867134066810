var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-map-marker',{attrs:{"lat":_vm.location.lat,"lng":_vm.location.lng,"point":_vm.point,"selected-classification-id":_vm.selectedClassificationId,"read-only":_vm.readOnly},on:{"change:latlng":function($event){return _vm.updateLocationLatLng($event)},"click":function($event){_vm.popup = !_vm.popup}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_vm._l((_vm.types),function(type,typeName){return [(_vm.hasType(type))?_c('div',{key:type,class:{
                    'icon': true,
                    [`icon--${typeName}`]: true,
                    'disabled': _vm.hasType(type) && !_vm.hasSelectedClassification(type),
                }}):_vm._e()]})]},proxy:true},{key:"popup",fn:function(){return [_vm._l((_vm.types),function(type){return _c('base-button',{key:type,class:{
                'button': true,
                'button--enabled': _vm.hasType(type) && _vm.hasSelectedClassification(type),
                'button--disabled': _vm.hasType(type) && !_vm.hasSelectedClassification(type),
            },attrs:{"btn11":"","badge":Object.entries({
                'fas': true,
                'fa-map-marker-alt': type === _vm.types.start,
                'fa-flag-checkered': type === _vm.types.meta,
                'fa-stopwatch': type === _vm.types.checkpoint,
                'fa-utensils': type === _vm.types.buffet,
                'fa-undo': type === _vm.types.return,
                'fa-exchange-alt': type === _vm.types.change,
                'fa-users': type === _vm.types.fans,
                'fa-id-card': type === _vm.types.office,
                'fa-parking': type === _vm.types.parking
            }).filter(([cls, x]) => x).map(([cls]) => cls).join(' ')},on:{"click":function($event){return _vm.toggleType(type)}}},[_vm._v(" "+_vm._s(_vm.typesNames[type])+" ")])}),_c('div',{staticClass:"line mat4"}),_c('base-button',{staticClass:"button mat4",attrs:{"btn11":"","badge":"fas fa-trash-alt"},on:{"click":function($event){return _vm.$emit('delete-point', _vm.point)}}},[_vm._v(" Usuń punkt ")])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }