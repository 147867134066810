var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"competition-box pointer",on:{"click":function($event){_vm.competition
            ? _vm.$router.push({
                name: 'minisite-landing',
                params: _vm.competition,
                alias: true,
            })
            : ''}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[(!_vm.competition)?_c('div',{staticClass:"competition-box__image d-flex"},[_c('div',{staticClass:"competition-box__image-content--skeleton"})]):_c('div',{staticClass:"competition-box__image",class:_vm.classPhoto},[_c('div',{staticClass:"competition-box__image-content",style:(_vm.styleColour)},[_c('p',{staticClass:"competition-box__category"},[_vm._v(" "+_vm._s(_vm._f("asType")(_vm.competition.type))+" ")])])])])]),_c('div',{staticClass:"row competition-box__description"},[_c('div',{staticClass:"col-12"},[(!_vm.competition)?_c('p',{staticClass:"base-skeleton-text competition-box__description-title--skeleton"}):_c('h2',[_vm._v(" "+_vm._s(_vm.competition.name)+" ")]),(!_vm.competition)?_c('div',{staticClass:"competition-box__status competition-box__status--skeleton competition-box__status--mobile"}):[([2, 3].includes(_vm.competition.status))?_c('div',{staticClass:"competition-box__status competition-box__status--mobile",class:_vm.competition.status !== 2
                            ? 'competition-box__status--close'
                            : 'competition-box__status--open'},[_vm._v(" "+_vm._s(_vm._f("asStatus")(_vm.competition.status))+" ")]):_vm._e(),([4, 5, 6].includes(_vm.competition.status))?_c('div',{staticClass:"competition-box__status competition-box__status--mobile competition-box__status--results"},[_vm._v(" "+_vm._s(_vm._f("asStatus")(_vm.competition.status))+" ")]):_vm._e()],(!_vm.competition)?_c('div',{staticClass:"competition-box__location"},[_c('p',{staticClass:"base-skeleton-text base-skeleton-text--location"}),_c('p',{staticClass:"base-skeleton-text base-skeleton-text--date"})]):_c('div',{staticClass:"competition-box__location"},[_c('p',[_vm._v(_vm._s(_vm.competition.location))]),_c('p',[_vm._v(_vm._s(_vm._f("asTime")(_vm.competition.startedTime)))])]),(
                    _vm.competition &&
                        _vm.ready &&
                        Object.keys(_vm.userPlayers).length &&
                        (_vm.competition.status == 2 || _vm.competition.isRemote || _vm.competition.status >= 5 )
                )?_vm._l((_vm.userPlayers),function(player){return _c('div',{key:player.id},[_c('div',{staticClass:"col-12 mab8"},[(
                                (Object.keys(_vm.userPlayers).length > 1 &&
                                    !_vm.isOnlyCurrentUserRegistered) ||
                                    (Object.keys(_vm.userPlayers).length == 1 &&
                                        player.user != _vm.user.id)
                            )?[_c('b',[_vm._v(_vm._s(player.firstname)+" "+_vm._s(player.lastname))]),_vm._v(" , ")]:_vm._e(),_vm._v(" "+_vm._s(_vm.classifications[player.classification].namePl)+" ")],2),(
                            _vm.competition.status <= 3 ||
                                (_vm.competition.status != 6 &&
                                    _vm.competition.isRemote)
                        )?_c('div',{staticClass:"row"},[_c('div',{staticClass:"competition-box__actions",class:{
                                mab16:
                                    !(_vm.lastUserPlayerId == player.id) ||
                                    _vm.competition.status >= 5,
                            }},[(_vm.canPlayerPay(player, _vm.competition))?_c('div',{staticClass:"col-12 col-lg-unset mar8"},[_c('base-button',{attrs:{"btn1":""},on:{"click":function($event){return _vm.$router.push({
                                            name: 'minisite-pay',
                                            params: {
                                                name: _vm.competition.name,
                                                id: _vm.competition.id,
                                                token: player.token,
                                            },
                                            alias: true,
                                        })}}},[_vm._v(" "+_vm._s(_vm.say.pay_for_participation)+" ")])],1):_vm._e(),_c('div',{staticClass:"col-12 col-lg-unset align-items-center"},[_c('div',{staticClass:"competition-box__actions-group"},[(
                                            _vm.canPlayerEdit(
                                                player,
                                                _vm.competition,
                                            )
                                        )?_c('base-button',{attrs:{"btn5":""},on:{"click":function($event){return _vm.$router.push({
                                                name: 'minisite-edit',
                                                params: {
                                                    name: _vm.competition.name,
                                                    id: _vm.competition.id,
                                                    token: player.token,
                                                },
                                                alias: true,
                                            })}}},[_vm._v(" "+_vm._s(_vm.say.edit)+" ")]):_vm._e(),(
                                            _vm.canPlayerTransfer(
                                                player,
                                                _vm.competition,
                                            )
                                        )?_c('base-button',{attrs:{"btn5":""},on:{"click":function($event){return _vm.$router.push({
                                                name: 'minisite-transfer',
                                                params: {
                                                    name: _vm.competition.name,
                                                    id: _vm.competition.id,
                                                    token: player.token,
                                                },
                                                alias: true,
                                            })}}},[_vm._v(" "+_vm._s(_vm.say.transfer_pay)+" ")]):_vm._e(),(
                                            _vm.canPlayerShop(
                                                player,
                                                _vm.competition,
                                            )
                                        )?_c('base-button',{attrs:{"btn5":""},on:{"click":function($event){return _vm.$router.push({
                                                name: 'minisite-shop',
                                                params: {
                                                    name: _vm.competition.name,
                                                    id: _vm.competition.id,
                                                    token: player.token,
                                                },
                                                alias: true,
                                            })}}},[_vm._v(" "+_vm._s(_vm.say.shop)+" ")]):_vm._e(),(
                                            _vm.canPlayerDeclareResult(
                                                player,
                                                _vm.competition,
                                            )
                                        )?_c('base-button',{attrs:{"btn5":""},on:{"click":function($event){return _vm.$router.push({
                                                name:
                                                    'minisite-declare-result',
                                                params: {
                                                    token: player.token,
                                                },
                                                alias: true,
                                            })}}},[_vm._v(" "+_vm._s(_vm.say.add_result)+" ")]):_vm._e()],1)]),(player.pay)?_c('div',{staticClass:"competition-box__actions-status c-positive mal15"},[_c('i',{staticClass:"fas fa-check-circle",attrs:{"aria-hidden":"true"}}),_vm._v(" "+_vm._s(_vm.say.payed)+" ")]):(
                                    _vm.classificationsSettings[
                                        player.classification
                                    ].isPay &&
                                        !player.pay &&
                                        (_vm.competition.status != 2 ||
                                            (_vm.limits[player.classification]
                                                .playersAvailable === 0 &&
                                                _vm.competitionSetting.playersKindLimit ===
                                                'pay'))
                                )?_c('div',{staticClass:"c-negative competition-box__actions-status mal15"},[_vm._v(" "+_vm._s(_vm.say.player_is_not_payed)+" ")]):_vm._e()]),(
                                _vm.lastUserPlayerId == player.id &&
                                    [2, 3].includes(_vm.competition.status)
                            )?_c('div',{staticClass:"competition-box__status competition-box__status--desktop competition-box__status--no-margin-bottom",class:[
                                _vm.competition.status !== 2
                                    ? 'competition-box__status--close'
                                    : 'competition-box__status--open ',
                            ]},[_vm._v(" "+_vm._s(_vm._f("asStatus")(_vm.competition.status))+" ")]):_vm._e()]):_vm._e(),(_vm.competition.status >= 5)?[_c('div',{staticClass:"competition-box__result",class:{
                                mab16: !(_vm.lastUserPlayerId == player.id),
                            }},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"row justify-content-start"},[(
                                            player.officialTime ||
                                                player.declaredTime
                                        )?[_c('div',{staticClass:"col-12 col-lg-4 competition-box__result-time"},[_c('p',[_vm._v(_vm._s(_vm.say.your_time))]),_c('strong',{staticClass:"l-tall"},[_vm._v(_vm._s(_vm._f("asStoper")((player.officialTime || player.declaredTime))))])]),_c('div',{staticClass:"col-12 col-lg-4 competition-box__result-place"},[_c('p',[_vm._v(" "+_vm._s(_vm.say.in_classification)+" ")]),(
                                                    player.placeClassification
                                                )?_c('strong',{staticClass:"mar8"},[_vm._v("#"+_vm._s(player.placeClassification))]):_vm._e()]),_c('div',{staticClass:"col-12 col-lg-4 competition-box__result-classification"},[_c('p',[_vm._v(_vm._s(_vm.say.in_category))]),(player.placeCategory)?_c('strong',{staticClass:"mar8"},[_vm._v("#"+_vm._s(player.placeCategory))]):_vm._e()])]:[_vm._v(" "+_vm._s(_vm.say.time_not_added)+" ")]],2)])])]:_vm._e()],2)}):(
                    _vm.competition && [2, 3].includes(_vm.competition.status)
                )?[_c('div',{staticClass:"competition-box__hint"},[_c('div',{staticClass:"competition-box__status competition-box__status--desktop",class:[
                            _vm.competition.status !== 2
                                ? 'competition-box__status--close'
                                : 'competition-box__status--open',
                        ]},[_vm._v(" "+_vm._s(_vm._f("asStatus")(_vm.competition.status))+" ")])])]:(
                    _vm.competition && [4, 5, 6].includes(_vm.competition.status)
                )?[_c('div',{staticClass:"competition-box__hint"},[_c('div',{staticClass:"competition-box__status competition-box__status--desktop competition-box__status--results"},[_vm._v(" "+_vm._s(_vm._f("asStatus")(_vm.competition.status))+" ")])])]:_vm._e(),(!_vm.competition)?_c('div',{staticClass:"competition-box__hint competition-box__hint--skeleton"},[_c('div',{staticClass:"competition-box__status w200px competition-box__status--skeleton competition-box__status--desktop"})]):_vm._e()],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }