<template>
    <div>
        <!-- Operator header. -->
        <panel-operator-header
            :operator-id="operatorId"
            :is-organizer-list.sync="isOrganizerList"
            @show-add-organizer="showAddOrganizer = !showAddOrganizer"
            @search-competitions="searchCompetitions = $event"
            @search-organizers="searchOrganizers = $event"
        />
        <!-- Adding new organizer. -->
        <transition name="operator-form">
            <panel-add-person
                v-if="showAddOrganizer"
                class="operator-header__add-box"
                @add-person="onAddOrganizer"
                @close="showAddOrganizer = false"
            />
        </transition>
        <!-- Loading state. -->
        <div
            v-if="!ready"
            class="container mat32"
        >
            <div class="row">
                <div class="col-12">
                    <div class="operator-header__loading-state">
                        Trwa wczytywanie
                        <div class="mal15 lds-ellipsis">
                            <div />
                            <div />
                            <div />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- List. -->
        <panel-list
            v-else
            :is-organizers="isOrganizerList"
            :is-competitions="!isOrganizerList"
            :items="items"
            @row-click="onRowClick"
            @switch-show-competition="onSwitchCompetition"
            @send-access="sendAccessToOrganizer"
            @delete-competition="
                (showPopupWithDelete = true), (competitionIdToDelete = $event)
            "
            @delete-person="
                (showPopupWithDelete = true), (organizerIdToDelete = $event)
            "
        />
        <!-- Pagination competitions list. -->
        <div
            v-if="!isOrganizerList && ready"
            class="container"
        >
            <div class="row operator__pages">
                <div
                    class="operator__pages-btn"
                    :class="{
                        'operator__pages-btn--disabled': !isPreviousPage,
                    }"
                    @click="isPreviousPage ? prevPage() : ''"
                >
                    <div
                        v-if="isLoadingPrevCompetitions"
                        class="operator__pages-loading"
                    >
                        <div class="lds-ellipsis">
                            <div />
                            <div />
                            <div />
                        </div>
                    </div>
                    Poprzednie
                </div>
                <div
                    class="operator__pages-btn"
                    :class="{ 'operator__pages-btn--disabled': !isNextPage }"
                    @click="isNextPage ? nextPage() : ''"
                >
                    <div
                        v-if="isLoadingNextCompetitions"
                        class="operator__pages-loading"
                    >
                        <div class="lds-ellipsis">
                            <div />
                            <div />
                            <div />
                        </div>
                    </div>
                    Następne
                </div>
            </div>
        </div>
        <base-popup v-if="showPopupWithDelete">
            <!-- Title. -->
            <template #title>
                {{ isOrganizerList ? "Usuń organizatora" : "Usuń zawody" }}
            </template>
            <!-- Subtitle. -->
            <template #subtitle>
                <b>Czy na pewno, chcesz usunąć
                    {{ isOrganizerList ? "organizatora" : "zawody" }}?</b>
            </template>
            <!-- Content. -->
            <template #content>
                <div
                    v-if="isOrganizerList"
                    class="mat16 c-negative"
                >
                    Zostaną usunięte wszystkie zawody organizatora
                </div>
                <div class="mat8">
                    Tej czynności nie będzie można cofnąć.
                </div>
            </template>
            <!-- Buttons. -->
            <template #buttons>
                <base-button
                    class="mar15"
                    btn2
                    @click="onClosePopup()"
                >
                    anuluj
                </base-button>
                <base-button
                    class="mal15"
                    btn1
                    @click="
                        onDelete(), (showPopupWithDelete = !showPopupWithDelete)
                    "
                >
                    Usuń
                </base-button>
            </template>
        </base-popup>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import {
    BOOTSTRAP_OPERATOR,
    BOOTSTRAP_ORGANIZER,
    READ_COMPETITIONS,
    UPDATE_COMPETITION,
    SEND_ORGANIZER_ACCESS,
    DELETE_COMPETITION,
    DELETE_ORGANIZER,
} from '@/store/actions.type';
import PanelAddPerson from '@/components/PanelAddPerson';
import PanelOperatorHeader from '@/components/PanelOperatorHeader';
import PanelList from '@/components/PanelList';
import { SET_NOTIFICATION } from '@/store/mutations.type';

export default {
    components: {
        PanelAddPerson,
        PanelList,
        PanelOperatorHeader,
    },
    data() {
        return {
            searchOrganizers: '',
            searchCompetitions: '',
            isOrganizerList: false,
            competitionsIds: [],
            organizersIds: [],
            initialOrganizersIds: [],
            ready: false,
            page: 1,
            isLoadingPrevCompetitions: false,
            isLoadingNextCompetitions: false,
            showAddOrganizer: false,
            showPopupWithDelete: false,
            competitionIdToDelete: null,
            organizerIdToDelete: null,
        };
    },
    computed: {
        ...mapGetters([
            'user',
            'organizers',
            'operators',
            'competitions',
            'competitionPlayerCounts',
        ]),
        isPreviousPage() {
            return this.page > 1;
        },
        isNextPage() {
            // Pagination is blocked if search input is in use
            return this.competitionsIds.length > 0 && !this.searchCompetitions;
        },
        competitionsFilter() {
            if (this.$router.matched('organizer-competitions', this.$route)) {
                return {
                    organizer: this.$route.params.organizerId,
                    panel: 1,
                    page: this.page,
                    itemsPerPage: 30,
                };
            }
            return {
                operator: this.operatorId,
                panel: 1,
                page: this.page,
                itemsPerPage: 30,
            };
        },
        isModerator() {
            return this.user.roles.includes('ROLE_ADMIN');
        },
        operatorId() {
            if (this.isModerator && this.$route.params.operatorId) {
                return this.$route.params.operatorId;
            }
            return this.user.operator;
        },
        items() {
            const list = [];
            if (!this.isOrganizerList) {
                for (const id of this.competitionsIds) {
                    const competition = this.competitions[id];
                    const competitionInfo = {
                        id,
                        startedTime: this.$options.filters.asTime(
                            competition.startedTime,
                        ),
                        name: competition.name,
                        registered: this.competitionPlayerCounts[id]
                            .playersCount,
                        paid: this.competitionPlayerCounts[id].playersPayCount,
                        location: competition.location,
                        status: this.$options.filters.asStatus(
                            competition.status,
                        ),
                        statusId: competition.status,
                        isShow: competition.mainPromo,
						organizerId: competition.organizer
                    };
                    list.push(competitionInfo);
                }
            } else {
                for (const id of this.organizersIds) {
                    const organizer = this.organizers[id];
                    const organizerInfo = {
                        id,
                        name: organizer.name,
                        phone: organizer.phone,
                        email: organizer.email,
                        addedTime: organizer.addedTime.format('DD.MM.YYYY'),
                    };
                    list.push(organizerInfo);
                }
            }
            return list;
        },
    },
    watch: {
        // Search competitions and organizers by text from inputs.
        async searchCompetitions() {
            if (this.searchCompetitions.length >= 3) {
                const { competitions } = await this.READ_COMPETITIONS({
                    ...this.competitionsFilter,
                    page: 1,
                    itemsPerPage: 10,
                    name: this.searchCompetitions,
                });
                this.competitionsIds = competitions;
            } else if (!this.searchCompetitions) {
                this.page = 1;
                const { competitions } = await this.READ_COMPETITIONS({
                    ...this.competitionsFilter,
                });
                this.competitionsIds = competitions;
            }
        },
        async searchOrganizers() {
            if (this.searchOrganizers.length) {
                const searchValue = this.searchOrganizers;
                const matchedItemsIds = [];
                for (const id of this.initialOrganizersIds) {
                    const org = this.organizers[id];
                    if (this.matchSome([org.name, org.email], searchValue)) {
                        matchedItemsIds.push(id);
                    }
                }
                this.organizersIds = matchedItemsIds;
            } else {
                this.organizersIds = this.initialOrganizersIds;
            }
        },
    },
    async created() {
        if (this.$route.query.q === 'lista-zawodow') {
            this.isOrganizerList = false;
        }
        const {
            competitionsIds,
            organizersIds,
        } = await this.BOOTSTRAP_OPERATOR(this.operatorId);
        this.competitionsIds = competitionsIds;
        this.organizersIds = organizersIds;
        this.initialOrganizersIds = organizersIds;
        this.ready = true;
    },

    methods: {
        ...mapMutations([SET_NOTIFICATION]),
        ...mapActions([
            BOOTSTRAP_OPERATOR,
            READ_COMPETITIONS,
            BOOTSTRAP_ORGANIZER,
            UPDATE_COMPETITION,
            SEND_ORGANIZER_ACCESS,
            DELETE_COMPETITION,
            DELETE_ORGANIZER,
        ]),
        async nextPage() {
            this.page += 1;
            this.isLoadingNextCompetitions = true;
            this.competitionsIds = await this.readCompetitions();
            this.isLoadingNextCompetitions = false;
        },
        async prevPage() {
            this.page -= 1;
            this.isLoadingPrevCompetitions = true;
            this.competitionsIds = await this.readCompetitions();
            this.isLoadingPrevCompetitions = false;
        },
        async readCompetitions() {
            const { competitions } = await this.READ_COMPETITIONS({
                ...this.competitionsFilter,
            });
            return competitions;
        },
        onAddOrganizer(id) {
            this.organizersIds.push(id);
            this.showAddOrganizer = false;
        },
        matchSome(arrayValues, searchValue) {
            const exp = new RegExp(searchValue, 'i');
            for (const value of arrayValues) {
                if (value.match(exp)) {
                    return true;
                }
            }
            return false;
        },
        // Click handler for competition or organizer click.
        onRowClick(item) {
            if (!this.isOrganizerList) {
                this.$router.push({
                    name: 'panel-competition-dashboard',
                    params: {
                        name: item.name,
                        id: item.id,
                    },
                    alias: true,
                });
            } else {
                this.$router.push({
                    name: 'panel-organizer',
                    params: {
                        organizerId: item.id,
                    },
                    alias: true,
                });
            }
        },
        // Visibility switch handler.
        async onSwitchCompetition(id) {
            const competition = {
                ...this.competitions[id],
                mainPromo: !this.competitions[id].mainPromo,
            };
            await this.UPDATE_COMPETITION(competition);
        },
        // send accest to organizer handler.
        async sendAccessToOrganizer(id) {
            try {
                await this.SEND_ORGANIZER_ACCESS(id);
                this.SET_NOTIFICATION('alerts_positive_22');
            } catch (e) {
                this.SET_NOTIFICATION('alerts_negative_4');
            }
        },
        onClosePopup() {
            this.organizerIdToDelete = null;
            this.competitionIdToDelete = null;
            this.showPopupWithDelete = false;
        },
        async onDelete() {
            if (this.isOrganizerList) {
                await this.deleteOrganizer(this.organizerIdToDelete);
                this.organizerIdToDelete = null;
            } else {
                await this.deleteCompetition(this.competitionIdToDelete);
                this.competitionIdToDelete = null;
            }
        },
        // Delete competition handler.
        async deleteCompetition(id) {
            try {
                const competitionIndex = this.competitionsIds.findIndex(
                    (competitionId) => competitionId === id,
                );
                this.competitionsIds.splice(competitionIndex, 1);
                await this.DELETE_COMPETITION(id);
                this.SET_NOTIFICATION('alerts_positive_56');
            } catch (e) {
                this.SET_NOTIFICATION('alerts_negative_4');
                this.competitionsIds.push(id);
            }
        },
        async deleteOrganizer(id) {
            try {
                const organizerIndex = this.organizersIds.findIndex(
                    (orgId) => orgId === id,
                );
                const organizerIndexInInitial = this.initialOrganizersIds.findIndex(
                    (orgId) => orgId === id,
                );
                this.initialOrganizersIds.splice(organizerIndexInInitial, 1);
                this.organizersIds.splice(organizerIndex, 1);
                await this.DELETE_ORGANIZER(id);
                this.SET_NOTIFICATION('alerts_positive_44');
            } catch (e) {
                this.SET_NOTIFICATION('alerts_negative_4');
                this.organizersIds.push(id);
                this.initialOrganizersIds.push(id);
            }
        },
    },
};
</script>

<style scoped>
.operator__pages {
    margin-top: 32px;
    margin-bottom: 32px;
}
.operator__pages-btn {
    cursor: pointer;
    text-decoration: underline;
    user-select: none;
    position: relative;
}

.operator__pages-btn--disabled {
    opacity: 0.5;
}
.operator__pages-loading {
    position: absolute;
    top: -25px;
    width: 100%;
    display: flex;
    justify-content: center;
}
.operator-header__loading-state {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.operator-header__add-box {
    overflow: hidden;
}

.operator-form-enter {
    max-height: 0;
}
.operator-form-enter-to {
    max-height: 500px;
}
.operator-form-leave {
    max-height: 500px;
}
.operator-form-leave-to {
    max-height: 0;
}
.operator-form-enter-active,
.operator-form-leave-active {
    transition: all var(--animation-slow);
}

/* End animation loading table */
/* Animation loading next/prev page */
.lds-ripple {
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;
}
.lds-ripple div {
    position: absolute;
    border: 1px solid #fff;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
}
@keyframes lds-ripple {
    0% {
        top: 8px;
        left: 8px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 0px;
        left: 0px;
        width: 16px;
        height: 16px;
        opacity: 0;
    }
}
/* End animation loading next/prev page */
</style>
