var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(
        !_vm.isClosedNoveltyCookie('stoper') &&
            !_vm.isClickedCloseNovelty &&
            !_vm.isMobile
    )?_c('panel-novelty-box',{on:{"close":function($event){return _vm.closeNoveltyMessage('stoper')}},scopedSlots:_vm._u([{key:"images",fn:function(){return [_c('img',{staticClass:"novelty__box-img novelty__box-img--laptop",attrs:{"src":require("@/assets/images/stoperInLaptop@3.png"),"alt":""}}),_c('img',{staticClass:"novelty__box-img novelty__box-img--phone",attrs:{"src":require("@/assets/images/stoperInPhone@3.png"),"alt":""}})]},proxy:true},{key:"title",fn:function(){return [_vm._v("Nowa wersja stopera!")]},proxy:true}],null,false,3915934777)},[_c('p',[_vm._v("• obsługa startu falowego netto/brutto")]),_c('p',[_vm._v("• dowolna ilość punktów pomiarowych")]),_c('p',[_vm._v("• możliwość edytowania czasów")]),_c('p',[_vm._v("• sygnalizacja o błędnych numerach")]),_c('p',{staticClass:"mat16"},[_vm._v(" W miejscach trudno dostępnych bez dostępu do "),_c('br'),_vm._v(" zasilenia, alternatywa dla sprzętu pomiarowego ")])]):_vm._e(),(
        !_vm.isClosedNoveltyCookie('insurance') &&
            !_vm.isClickedCloseNovelty &&
            !_vm.isMobile
    )?_c('panel-novelty-box',{on:{"close":function($event){return _vm.closeNoveltyMessage('insurance')}},scopedSlots:_vm._u([{key:"images",fn:function(){return [_c('img',{staticClass:"novelty__box-img",attrs:{"src":require("@/assets/images/novelty-insurance.png"),"alt":""}})]},proxy:true},{key:"title",fn:function(){return [_vm._v("Uruchom sprzedaż ubezpieczeń")]},proxy:true}],null,false,1982657381)},[_c('p',[_vm._v("Daj uczestnikom możliwość ubezpieczenia startu w zawodach podczas zapisów. Zawodnicy będą mogli wykupić ubezpieczenia od:")]),_c('p',[_vm._v("• rezygnacji z udziału w zawodach")]),_c('p',[_vm._v("• następstw nieszczęśliwych wypadków")]),_c('p',{staticClass:"mat16"},[_vm._v("To tylko jeden dodatkowy krok w formularzu rejestracyjnym.")])]):_vm._e(),(
        !_vm.isClosedNoveltyCookie('msg') &&
            !_vm.isClickedCloseNovelty &&
            !_vm.isMobile
    )?_c('panel-novelty-box',{on:{"close":function($event){return _vm.closeNoveltyMessage('msg')}},scopedSlots:_vm._u([{key:"images",fn:function(){return [_c('img',{staticClass:"novelty__box-img",attrs:{"src":require("@/assets/images/novelty-msg.png"),"alt":""}})]},proxy:true},{key:"title",fn:function(){return [_vm._v("Wysyłaj wiadomości uczestnikom")]},proxy:true}],null,false,1183600172)},[_c('p',[_vm._v("Komunikuj się z zawodnikami zapisanymi na Twoje wydarzenie poprzez wiadomości mailowe.")]),_c('p',[_vm._v("• personalizacja poprzez znaczniki (np. imię, numer, link)")]),_c('p',[_vm._v("• możliwość dodania załączników")]),_c('p',[_vm._v("• targetowanie do grup uczestników (kategorie, opłaceni/nieopłaceni)")]),_c('p',{staticClass:"mat16"},[_vm._v("Pozostań w kontakcie!")])]):_vm._e(),(
        !_vm.isClosedNoveltyCookie('shop') &&
            !_vm.isClickedCloseNovelty &&
            !_vm.isMobile
    )?_c('panel-novelty-box',{on:{"close":function($event){return _vm.closeNoveltyMessage('shop')}},scopedSlots:_vm._u([{key:"images",fn:function(){return [_c('img',{staticClass:"novelty__box-img",attrs:{"src":require("@/assets/images/novelty-shop.png"),"alt":""}})]},proxy:true},{key:"title",fn:function(){return [_vm._v("Dodaj produkty do Twojego formularza zapisów")]},proxy:true}],null,false,3549502007)},[_c('p',[_vm._v("Daj uczestnikom możliwość kupienia dodatkowych pamiątek lub produktów potrzebnych do startu")]),_c('p',[_vm._v("• ustaw produkty i ich ceny")]),_c('p',[_vm._v("• ustaw dodatkowe opcje np: rozmiary, kolory")]),_c('p',{staticClass:"mat16"},[_vm._v("To tylko jeden dodatkowy krok w formularzu rejestracyjnym.")])]):_vm._e(),(
        !_vm.isClosedNoveltyCookie('gps') &&
            !_vm.isClickedCloseNovelty &&
            !_vm.isMobile
    )?_c('panel-novelty-box',{on:{"close":function($event){return _vm.closeNoveltyMessage('gps')}},scopedSlots:_vm._u([{key:"images",fn:function(){return [_c('img',{staticClass:"novelty__box-img",attrs:{"src":require("@/assets/images/novelty-gps.png"),"alt":""}})]},proxy:true},{key:"title",fn:function(){return [_vm._v("Potrzebujesz śledzenie GPS na Twoich zawodach?")]},proxy:true}],null,false,1902058288)},[_c('p',[_vm._v("Zapewnij bezpieczeństwo swoim zawodnikom – niech będą widoczni na trasie dzięki trackingowi GPS.")]),_c('p',[_vm._v("• śledzenie zmagań zawodników na żywo")]),_c('p',[_vm._v("• większe bezpieczeństwo dzięki lokalizacji startujących")]),_c('p',[_vm._v("• długi czas pracy nadajników")]),_c('p',[_vm._v("• zapis śladu GPX trasy")]),_c('p',{staticClass:"mat16"},[_vm._v("Wszystko to dzięki integracji systemu z serwisem Activeprogress.eu")])]):_vm._e(),(
        !_vm.isClosedNoveltyCookie('lang') &&
            !_vm.isClickedCloseNovelty &&
            !_vm.isMobile
    )?_c('panel-novelty-box',{on:{"close":function($event){return _vm.closeNoveltyMessage('lang')}},scopedSlots:_vm._u([{key:"images",fn:function(){return [_c('img',{staticClass:"novelty__box-img",attrs:{"src":require("@/assets/images/novelty-lang.png"),"alt":""}})]},proxy:true},{key:"title",fn:function(){return [_vm._v("Przetłumacz stronę zawodów na inne języki")]},proxy:true}],null,false,1448567855)},[_c('p',[_vm._v("W prosty sposób zamień swoją stronę zapisów w wielojęzyczną.")]),_c('p',[_vm._v("• obsługa kilku języków (EN, CZ, DE, FR)")]),_c('p',[_vm._v("• zwiększenie zasięgów strony zapisów")]),_c('p',[_vm._v("• dodanie plików (np. regulaminu) w innym języku")]),_c('p',[_vm._v("• tłumaczenia informacji, klasyfikacji, pól i komunikatów")])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }