var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('panel-organizer-header',{attrs:{"organizer-id":_vm.organizerId},on:{"search-competitions":function($event){_vm.searchInput = $event},"add-competition":_vm.onAddCompetition}}),(!_vm.ready)?_c('div',{staticClass:"container mat32"},[_vm._m(0)]):(_vm.ready && _vm.competitionsIds.length)?_c('panel-list',{attrs:{"is-competitions":"","items":_vm.items},on:{"row-click":_vm.onRowClick,"switch-show-competition":_vm.switchShowCompetitions,"delete-competition":function($event){(_vm.showPopupWithDelete = true), (_vm.competitionIdToDelete = $event)}}}):(
            _vm.ready &&
                !_vm.competitionsIds.length &&
                _vm.page === 1 &&
                !_vm.isLoadingPrevCompetitions
        )?_c('organizer-account-hint',{attrs:{"organizer-id":_vm.organizerId}}):_vm._e(),(_vm.ready)?_c('div',{staticClass:"container"},[_c('div',{staticClass:"row organizer__pages"},[_c('div',{staticClass:"organizer__pages-btn",class:{
                    'organizer__pages-btn--disabled': !_vm.isPreviousPage,
                },on:{"click":function($event){_vm.isPreviousPage ? _vm.prevPage() : ''}}},[(_vm.isLoadingPrevCompetitions)?_c('div',{staticClass:"organizer__pages-loading"},[_vm._m(1)]):_vm._e(),_vm._v(" Poprzednie ")]),_c('div',{staticClass:"organizer__pages-btn",class:{ 'organizer__pages-btn--disabled': !_vm.isNextPage },on:{"click":function($event){_vm.isNextPage ? _vm.nextPage() : ''}}},[(_vm.isLoadingNextCompetitions)?_c('div',{staticClass:"organizer__pages-loading"},[_vm._m(2)]):_vm._e(),_vm._v(" Następne ")])])]):_vm._e(),(_vm.showPopupWithDelete)?_c('base-popup',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Usuń zawody ")]},proxy:true},{key:"subtitle",fn:function(){return [_c('b',[_vm._v("Czy na pewno, chcesz usunąć zawody?")])]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"mat16"},[_vm._v(" Tej czynności nie będzie można cofnąć. ")])]},proxy:true},{key:"buttons",fn:function(){return [_c('base-button',{staticClass:"mar15",attrs:{"btn2":""},on:{"click":function($event){return _vm.onClosePopup()}}},[_vm._v(" anuluj ")]),_c('base-button',{staticClass:"mal15",attrs:{"btn1":""},on:{"click":function($event){_vm.deleteCompetition(_vm.competitionIdToDelete),
                    (_vm.showPopupWithDelete = !_vm.showPopupWithDelete)}}},[_vm._v(" Usuń ")])]},proxy:true}],null,false,1924180952)}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"organizer-header__loading-state"},[_vm._v(" Trwa wczytywanie "),_c('div',{staticClass:"mal15 lds-ellipsis"},[_c('div'),_c('div'),_c('div')])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"lds-ellipsis"},[_c('div'),_c('div'),_c('div')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"lds-ellipsis"},[_c('div'),_c('div'),_c('div')])
}]

export { render, staticRenderFns }