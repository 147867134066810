var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{ref:"list",staticClass:"row container-list"},[_c('base-table',{staticClass:"list",attrs:{"headers":_vm.headers,"items":_vm.items},scopedSlots:_vm._u([{key:"header",fn:function({ sort }){return [_c('tr',{staticClass:"list__row"},_vm._l((_vm.headers),function(header,column){return _c('th',{key:column,staticClass:"list__row-header",on:{"click":function($event){return sort(column)}}},[_vm._v(" "+_vm._s(header.text)+" ")])}),0)]}},{key:"body",fn:function({ displayedItems }){return _vm._l((displayedItems),function(item){return _c('tr',{key:item.id,staticClass:"list__row list__row--pointer list__row--hover",class:{
                            'list__row--disabled':
                                _vm.isCompetitions && !item.isShow,
                        },on:{"click":function($event){return _vm.onRowClick(item)}}},_vm._l((_vm.headers),function(header,column){return _c('td',{key:column,staticClass:"list__row-data",class:{
                                'list__row-data--sticky-right':
                                    header.value === 'more',
                                'list__row-data--name': header.value === 'name',
                                'list__row-data--location':
                                    header.value === 'location',
                                'list__row-data--active':
                                    header.value === 'more' &&
                                    item.id === _vm.menuCompetitionId,
                                'list__row-data--loading': _vm.loading
                            },on:{"click":function($event){header.value === 'more'
                                    ? _vm.menuClickHandler($event, item.id)
                                    : header.value === 'status' ? _vm.statusClickHandler($event) : ''}}},[(header.value === 'more')?[_c('div',{ref:item.id,refInFor:true,staticClass:"more-btn"},[_c('i',{staticClass:"fas fa-ellipsis-h"}),(_vm.menuCompetitionId === item.id)?_c('div',{staticClass:"more-btn__content",class:{
                                            'more-btn__content--from-up': _vm.isMenuCompetitionIdInBottom,
                                        }},[(_vm.isCompetitions)?[_c('div',{staticClass:"more-btn__content-btn",on:{"click":function($event){return _vm.$router.push({
                                                        name:
                                                            'panel-competition-stoper',
                                                        params: item,
                                                        alias: true,
                                                    })}}},[_c('i',{staticClass:"fas fa-stopwatch mar8"}),_vm._v(" Otwórz stoper ")]),_c('div',{staticClass:"more-btn__content-btn",on:{"click":function($event){return _vm.copyCompetition(item.id)}}},[_c('i',{staticClass:"far fa-copy mar8"}),_vm._v(" Kopiuj zawody ")]),_c('div',{staticClass:"more-btn__content-btn",on:{"click":function($event){return _vm.$emit(
                                                        'switch-show-competition',
                                                        item.id,
                                                    )}}},[_c('i',{staticClass:"far mar8",class:[
                                                        item.isShow
                                                            ? 'fa-eye-slash'
                                                            : 'fa-eye',
                                                    ]}),_vm._v(" "+_vm._s(!item.isShow ? 'Pokaż na liście zawodów' : 'Ukryj na liście zawodów')+" ")]),(_vm.isAdmin)?_c('div',{staticClass:"more-btn__content-btn",on:{"click":function($event){return _vm.$emit(
                                                        'switch-global-shop-competition',
                                                        item.id,
                                                    )}}},[_c('i',{staticClass:"far mar8",class:[
                                                        item.globalShop
                                                            ? 'fa-cart-arrow-down'
                                                            : 'fa-cart-plus',
                                                    ]}),_vm._v(" "+_vm._s(!item.globalShop ? 'Otwórz sklep partnerski' : 'Zamknij sklep partnerski')+" ")]):_vm._e(),_c('div',{staticClass:"more-btn__content-btn",on:{"click":function($event){return _vm.$emit(
                                                        'delete-competition',
                                                        item.id,
                                                    )}}},[_c('i',{staticClass:"far fa-trash mar8"}),_vm._v(" Usuń zawody ")]),(_vm.isAdmin)?[_c('div',{staticClass:"more-btn__content-btn",on:{"click":function($event){return _vm.$emit(
                                                            'promote-competition',
                                                            item.id,
                                                        )}}},[_c('i',{staticClass:"fas fa-dollar-sign mar8"}),_vm._v(" Promuj ")]),_c('div',{staticClass:"more-btn__content-btn",on:{"click":function($event){return _vm.$emit(
                                                            'gamification-competition',
                                                            item.id,
                                                        )}}},[_c('i',{staticClass:"fas fa-trophy mar8"}),_vm._v(" "+_vm._s(item.isGamification ? 'Usuń grywalizacje' : 'Grywalizacja')+" ")])]:_vm._e()]:[_c('div',{staticClass:"more-btn__content-btn",on:{"click":function($event){return _vm.$emit(
                                                        'send-access',
                                                        item.id,
                                                    )}}},[_c('i',{staticClass:"far fa-envelope mar8"}),_vm._v(" "+_vm._s(_vm.isOrganizers ? 'Wyślij dostęp organizatorowi' : 'Wyślij dostęp operatorowi')+" ")]),_c('div',{staticClass:"more-btn__content-btn",on:{"click":function($event){return _vm.$emit(
                                                        'delete-person',
                                                        item.id,
                                                    )}}},[_c('i',{staticClass:"far fa-trash mar8"}),_vm._v(" "+_vm._s(_vm.isOrganizers ? 'Usuń organizatora' : 'Usuń operatora')+" ")])]],2):_vm._e()])]:(header.value === 'status')?[_c('div',{staticStyle:{"width":"200px"}},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedStatus[item.id]),expression:"selectedStatus[item.id]"}],on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.selectedStatus, item.id, $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.checkOrganizer(item)}]}},_vm._l((_vm.statuses),function(status){return _c('option',{domProps:{"value":status}},[_vm._v(_vm._s(status.label))])}),0)])]:[_vm._v(" "+_vm._s(item[header.value])+" ")]],2)}),0)})}},{key:"no-results",fn:function({ search: term }){return [(term.length)?_c('div',[_vm._v(" Brak wyników wyszukiwania "),_c('b',[_vm._v(_vm._s(term))])]):(_vm.isCompetitions)?_c('div',[_vm._v(" Brak zorganizowanych zawodów ")]):(_vm.isOrganizers)?_c('div',[_vm._v(" Brak organizatorów ")]):_c('div',[_vm._v(" Brak operatorów ")])]}}])})],1),(_vm.showOrganizerModal)?_c('CheckOrganizerSetup',{attrs:{"id":_vm.organizerId},on:{"change":function($event){return _vm.setStatus(_vm.statusItem)},"close":_vm.closeOrganizerModalEvent}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }