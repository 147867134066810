<template>
	<div class="mat40">
		<panel-competition-form-box v-if="newAgreements && Object.keys(newAgreements).length > 0" class="panel-form mab24">
			<div class="col-12 mab24">
				<h2>Umowy wymagane do zaakceptowania</h2>
			</div>
			<div class="agreement-items">
				<div v-for="(agreement, index) in newAgreements" :key="index" class="agreement-item">
					<b class="agreement-name">Umowa z {{ operatorNames(agreement.contractor) }}</b>

						<div class="agreement-date">
							<base-button
								btn9
								icon="dsi dsi-download"
								@click="getSampleAgreement(agreement.principal, agreement.contractor)"
							>
								<span>Pobierz wzór umowy</span>
							</base-button>
						</div>
						<div class="agreement-actions">
							<base-button @click="postAgreement(agreement)" btn1 short>
								<span>AKCEPTUJĘ</span>
							</base-button>
						</div>
				</div>
			</div>
		</panel-competition-form-box>
		<panel-competition-form-box v-if="agreements && Object.keys(agreements).length > 0" class="panel-form mab24">
			<div class="col-12 mab24">
				<h2>Moje umowy</h2>
			</div>
			<div class="agreement-items">
				<div v-for="(agreement, index) in agreements" :key="index" class="agreement-item">
					<b class="agreement-name">Umowa z {{ agreement.fvFirmname }}</b>
					<b class="agreement-date">{{ timestampToDate(agreement.addedTime) }}</b>
					<div class="agreement-actions">
						<base-button
						btn9
						icon="dsi dsi-download"
						@click="downloadAgreement(agreement.secretId)"
					>
						<span>Pobierz umowę</span>
					</base-button>
					</div>
				</div>
			</div>
		</panel-competition-form-box>
	</div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import PanelCompetitionFormBox from '@/components/PanelCompetitionFormBox';
import { format, fromUnixTime } from "date-fns";
import { API_ADDRESS } from '@/config';

export default {
	name: 'PanelAccountSettingsAgreements',
	props: {
        id: {
            type: Number,
            default: null,
        },
	},
	components: {
		PanelCompetitionFormBox,
	},
	data() {
		return {
			loading: false,
			agreements: null,
			newAgreements: null
		}
	},
	computed: {
        ...mapGetters(['user', 'operators']),
		operatorId() {
			return this.id ? null : this.user.operator
		},
		organizerId() {
			return this.id || this.user.organizer
		},
		operatorNames() {
			return (id) => {
				if (this.operators[id]) {
					return this.operators[id].name;
				} else {
					this.getOperatorName(id).then(name => {
						this.$set(this.operators, id, { name });
					});
					return '...';
				}
			};
		}
    },
	created() {
		this.getAgreementsData()
	},
	methods: {
		async getAgreementsData() {
			const userId = this.operatorId || this.organizerId
			const promises = []
			const checkAgreements = axios({
				method: 'get',
				url: `/agreement/${userId}/check`,
			})
			promises.push(checkAgreements)

			if (this.operatorId) {
				const checkAgreementsOperator = axios({
					method: 'get',
					url: `/agreement/${userId}/operator`,
				})
				promises.push(checkAgreementsOperator)
			}
			if (this.organizerId) {
				const checkAgreementsOrganizer = axios({
					method: 'get',
					url: `/agreement/${userId}/organizer`,
				})
				promises.push(checkAgreementsOrganizer)
			}
			await Promise.all(promises).then((values) => {
				this.newAgreements = values[0]
				this.agreements = values[1]
			})
		},
		async postAgreement(agreement) {
			const payload = {
				administrator: agreement.principal,
				processor: agreement.contractor
			}
			try {
				await axios.post(`/agreement/dpa`, payload);
				this.getAgreementsData()
			} catch (e) {
				console.log(e)
				return;
			}
		},
		async getOperatorName(id) {
			const response = await axios({
				method: 'get',
				url: `/panel/operators/${id}`,
			});
			return response.name; 
		},
		timestampToDate(timestamp) {
			return format(fromUnixTime(timestamp), 'dd.MM.yyyy')
		},
		getSampleAgreement(administrator, processor) {
			window.open(`${API_ADDRESS}/agreement/sample?administrator=${administrator}&processor=${processor}`, '_blank')
		},
		downloadAgreement(secretId) {
			window.open(`${API_ADDRESS}/agreement/${secretId}/print`, '_blank')
		}
	}
}
</script>

<style scoped>
.agreement-items {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 8px;
}
.agreement-item {
	border-radius: 4px;
	background-color: var(--color-white, #fff);
    color: var(--color-charcoal-grey-two, #37474f);
	display: flex;
    align-items: center;
    padding: 16px;
}

.agreement-name {
	width: 55%;
}

.agreement-date,
.agreement-actions {
	margin-left: auto;
}

@media (max-width: 991px) {
	.agreement-item {
		flex-wrap: wrap;
	}
	.agreement-actions {
		margin-top: 8px;
		width: 100%;
	}
}
</style>	